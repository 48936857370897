import BlogId from "../enums/blogId.enum"

export const BlogData = [{
  id: BlogId.CALCULATOR,
  title: `Cardiovascular Risk Calculators: Why They Miss the Mark`,
  description: <>
    Risk calculators like the <i>Framingham Risk Score</i> have long been a cornerstone of cardiovascular medicine, estimating your chances of a heart attack or stroke over the next 10 years.<br/><br/>
    But here's the problem: cardiovascular disease (CVD) is a slow, silent process that often begins in your teens and unfolds over decades. Limiting the assessment to just 10 years is far too narrow to fully capture the long-term damage accumulating in your arteries over a lifetime.
  </>,
  // img: "/img/blogs/calculator.jpg",
  publishedAt: 'Dec 19, 2024',
  readTime: "8 Min Read",
  isPublished: true,
  authorPhoto: "/img/alex-square.jpg",
  authorName: "Alex Cheung",
}, {
  id: BlogId.TESTOSTERONE_AGE,
  title: `How Fast Do Testosterone Levels Decline with Age?`,
  description: <>
    Despite its reputation as a “male hormone”, testosterone's role goes far beyond defining male traits like facial hair and a deeper voice. It's a critical regulator of metabolism, a key supporter of muscle and bone health, and a significant driver of mood, energy and libido. <br/><br/> Testosterone production ramps up from birth into adulthood, peaking in your 20s. But after age 30, levels begin to decline. This isn't just a minor shift—it's a biological change that can impact athletic performance, energy, and overall vitality. Left unchecked, falling testosterone can result in reduced muscle mass and a higher risk of cardiovascular issues.
  </>,
  img: "/img/testosterone-age.jpg",
  publishedAt: 'Dec 2, 2024',
  readTime: "7 Min Read",
  isPublished: true,
  authorPhoto: "/img/alex-square.jpg",
  authorName: "Alex Cheung",

}, {
  id: BlogId.STATIN,
  title: `Are Statins Worth the Risk?`,
  description: <>
    Statins are among the most commonly prescribed medications worldwide for managing cholesterol levels. While their effectiveness in reducing cardiovascular risks is well-established, concerns about potential side effects often deter people from taking them. <br/><br/>  Here, we'll explore how statins work, assess their benefits, and weigh them against their risks to help you make an informed decision.
  </>,
  img: "/img/rosuvastatin.jpg",
  publishedAt: 'Nov 21, 2024',
  readTime: "4 Min Read",
  isPublished: true,
},  {
  id: BlogId.PLAQUE_REVERSAL,
  title: `Reversing Plaque Buildup in Your Arteries: Is It Possible?`,
  description: <>
    Plaque buildup in your arteries is one of the primary culprits behind heart attacks and strokes. A common misconception is that this buildup is inevitable—a one-way street where you can only hope to slow its progression. However, emerging research paints a more hopeful picture. <br/><br/> Studies show that by lowering LDL cholesterol levels significantly, it is not only possible to halt the progression of plaque buildup but also to reverse it. In other words, the plaques in your arteries can actually shrink, opening the door to reduced heart disease risk.
  </>,
  img: "/img/atherosclerosis.jpg",
  publishedAt: 'Nov 20, 2024',
  readTime: "5 Min Read",
  isPublished: true,
}, {
  id: BlogId.LPA,
  title: `Genetics, Lp(a), and Heart Attack Risk`,
  description: <>
    Lp(a), or Lipoprotein(a), can be thought of as a tiny package of cholesterol in your blood, wrapped in a unique protein called apolipoprotein(a). It's a wildcard in heart health— Lp(a) levels are mostly determined by genes and don't change much with diet or exercise. <br/><br/> High levels of Lp(a) can increase your risk of heart attack, but there's a catch: the amount of risk associated with high levels of Lp(a)  depends on your ethnicity.
  </>,
  publishedAt: 'Nov 18, 2024',
  readTime: "4 Min Read",
  isPublished: true,
}, {
  id: BlogId.BLOOD_PRESSURE_DIET,
  title: `Can You Lower Blood Pressure with Diet Changes?`,
  description: <>
    For years, most people have accepted high blood pressure as a problem that needs pills to fix. And while medication is crucial for some, what if changing your diet could naturally lower your blood pressure?
  </>,
  publishedAt: 'Nov 10, 2024',
  readTime: "7 Min Read",
  isPublished: true,
  img: "/img/blogs/bp-diet.jpg"
}, {
  id: BlogId.SUPPLEMENT,
  title: `What Is the Most Effective Supplement to Lower LDL Cholesterol?`,
  description: <>
    At Instalab, we're all about giving you safe, effective options for improving your health. While prescription medications for high cholesterol are necessary for some people, several over-the-counter supplements can also reduce LDL cholesterol—sometimes achieving results that rival those of prescribed medications.
  </>,
  publishedAt: 'Nov 9, 2024',
  readTime: "3 Min Read",
  isPublished: true,
  img: "/img/blogs/berberine.jpg"
}, {
  id: BlogId.APOB,
  title: `Beyond Cholesterol: Why ApoB Matters for Heart Health`,
  description: <>
    ApoB, short for Apolipoprotein B, measures the number of atherogenic particles carrying cholesterol in your bloodstream. Think of these particles, or lipoproteins, as delivery trucks. Whether a truck is large or small, it has one ApoB, making it a direct count of how many trucks are on the road. <br /><br /> Here's the catch: even if your cholesterol levels look normal, too many ApoB particles can cause a traffic jam in your arteries, significantly increasing your cardiovascular risk.
  </>,
  publishedAt: 'Nov 8, 2024',
  readTime: "6 Min Read",
  isPublished: true,
  img: "/img/heart-health-box.png"
}, {
  id: BlogId.PCSK9I,
  title: `How Far Can PCSK9 Inhibitors Take You in the Fight Against Heart Disease?`,
  description: <>
    It's an exciting time to be involved in cardiovascular medicine. For years, statins have been the cornerstone of cholesterol management, effectively lowering low-density lipoprotein (LDL) cholesterol levels. However, many individuals still struggle to reach their cholesterol goals through lifestyle changes and statin therapy alone. <br/><br/> That's where PCSK9 inhibitors come into play.
  </>,
  publishedAt: 'Nov 7, 2024',
  readTime: "6 Min Read",
  isPublished: true,
}, {
  id: BlogId.OVEREXERCISE,
  title: `Can You Exercise Too Much?`,
  description: `We all get it—exercise isn't just good, it's foundational for longevity. But what about those of us pushing the limits, marathon after marathon, triathlon after triathlon? Can training for years at high intensity come with its own risks?`,
  publishedAt: 'Nov 5, 2024',
  readTime: "3 Min Read",
  isPublished: true,
}, {
  id: BlogId.FORECAST,
  title: `The Growing Burden of Heart Disease in the United States: A Forecast for the Future`,
  description: <>
    Heart disease remains one of the most significant health challenges in the U.S., affecting millions of lives each year. According to the latest data from the American Heart Association's (AHA) 2024 Heart Disease and Stroke Statistics report, heart disease continues to be the leading cause of death in the country.<br/><br/> And the outlook is alarming: projections indicate that by 2050, more than half of the U.S. adult population will have some form of cardiovascular disease (CVD), highlighting the need for urgent action to address this growing epidemic.
  </>,
  publishedAt: 'Oct 23, 2024',
  readTime: "5 Min Read",
  isPublished: true,
}, {
  id: BlogId.SOFT_PLAQUE,
  title: `Why Soft Plaque is Dangerous: Understanding the Silent Threat to Your Heart Health`,
  description: `When we think about heart health, many of us are familiar with the idea of "hardening" arteries or the buildup of calcium in the blood vessels. But there's another, more dangerous culprit lurking within your arteries that often goes unnoticed: soft plaque. Unlike its calcified counterpart, soft plaque can be more unpredictable and potentially life-threatening, even when you feel perfectly healthy.`,
  publishedAt: 'Oct 19, 2024',
  readTime: "4 Min Read",
  isPublished: true,
}, {
  id: BlogId.INSURANCE,
  title: `Maximize Your Health Savings with Instalab: Using HSA and FSA to Stay Proactive`,
  description: <>
    Health Savings Accounts (HSAs) and Flexible Spending Accounts (FSAs) are powerful tools to help you manage healthcare costs while focusing on proactive health care. By understanding how these accounts work and how you can leverage them for services like those provided by Instalab, you can make significant strides in your health journey—without breaking the bank.<br/><br/> Here's how you can make the most of your HSA and FSA, especially with the services available through Instalab.
  </>,
  publishedAt: 'Oct 15, 2024',
  readTime: "4 Min Read",
  isPublished: true,
  img: "/img/hsa-fsa.jpg"
}, {
  id: BlogId.CAC,
  title: `How a CAC Test Could Save Your Life`,
  description: <>
    The Coronary Artery Calcium (CAC) test is a powerful, non-invasive tool for assessing your risk of heart disease. It's a quick scan that measures the calcium buildup in the arteries of your heart, an early indicator of atherosclerosis—a condition that leads to heart disease.<br/><br/> By providing a CAC score, the test offers a clear picture of your future risk of cardiovascular events, such as heart attacks. The higher your CAC score, the more calcified plaque you have in your arteries, increasing your heart disease risk. On the other hand, a score of zero indicates a low risk of heart disease.
  </>,
  publishedAt: 'Sep 6, 2024',
  readTime: "4 Min Read",
  isPublished: true,
  img: "/img/cac.jpg",
}, {
  id: BlogId.CUMULATIVE_EXPOSURE,
  title: `Why a More Aggressive Approach to Lowering Cholesterol Is Necessary`,
  description: `Most doctors will tell you a LDL Cholesterol of 100 mg/dL is “good”, leading many of us to believe that we have eliminated the risk of this disease if we hit this number. But this one-size-fits-all approach grossly oversimplifies the complexity of cardiovascular risk and leaves many of us unknowingly at high risk for heart-related events.`,
  publishedAt: 'Feb 13, 2024',
  readTime: "7 Min Read",
  isPublished: true,
}, {
  id: BlogId.GALLERI,
  title: `Is the Galleri Test Worth It?`,
  description: <>
    Early detection of cancer significantly improves survival rates. <br/><br/> The Galleri test requires only a simple blood draw and scans for over 50 types of cancer. Known for its high specificity, the test does, however, have limitations in sensitivity, particularly in the early stages of cancer. Nevertheless, it offers invaluable information into potential cancer threats, especially for those genetically predisposed to the disease.
  </>,
  publishedAt: 'Dec 20, 2023',
  readTime: "5 Min Read",
  isPublished: true,
  img: "/img/galleri.png"
}, {
  id: BlogId.PEAK,
  title: `Peak Health Ranges: A Data-Driven Approach`,
  description: <>
    Understanding your lab results is crucial to making well-informed health decisions. Traditional reference ranges, based on average values of a healthy population, define normal upper and lower limits for lab tests. <br/><br/> While useful, these ranges can fall short in actually detecting the early stages of disease and extending lifespan and healthspan. This gap is addressed by the concept of 'peak health' ranges, which provide a more nuanced, individualized, and proactive approach to health management.
  </>,
  publishedAt: 'Dec 18, 2023',
  readTime: "6 Min Read",
  isPublished: true,
}, {
  id: BlogId.SAUNA,
  title: `Will a Sauna Make You Live Longer?`,
  description: <>
    Sure, your favorite podcaster loves saunas. Peter Attia, Andrew Huberman, Rhonda Patrick, take your pick. But if you trace their advice back to the original research papers, it all stems from two Finnish scientists: Tanjaniina Laukkanen (researcher) and Jari Laukkanen (professor, cardiologist). <br/><br/> The participants in their studies were predominantly middle-aged Finns (42-60 years old), hailing from a small Finnish town called Kuopio. As a culture, Finland is obsessed with saunas with around 60% of Finnish households having a sauna on-site. It's not surprising this is where most of the research originates.
  </>,
  publishedAt: 'Nov 27, 2023',
  readTime: "4 Min Read",
  isPublished: true,
  img: "/img/blogs/sauna.jpg"
}, {
  id: BlogId.FOUNDER,
  title: `Founder Health: The Silent Sacrifice in the Pursuit of Success`,
  description: <>
    Starting a startup often means pushing health to the back burner. In the race to success, it's easy to think "I'll deal with my health when my startup succeeds." <br/><br/> But "success" turns out to be a moving goal post. From raising the next big round, to making that pivotal hire, to achieving a growth milestone, to navigating a board issue. It's never ending. Your health is perpetually sidelined.
  </>,
  publishedAt: 'Nov 5, 2023',
  readTime: "4 Min Read",
  isPublished: true,
  img: "/img/blogs/founder.jpg",
  authorPhoto: "/img/adora-square.jpg",
  authorName: "Adora Cheung",
}]
