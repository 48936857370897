import { Button, Col, Row, Timeline } from 'antd'
import { useNavigate } from 'react-router-dom'
import PosthogHelper from '../../helpers/posthog.helper'
import { useRef, useEffect, useContext, useState } from 'react'
import './home.scss'
import EventCode from '../../enums/eventCode.enum'
import FlowType from '../../enums/flowType.enum'
import { PgFooter } from '../pgFooter/pgFooter.component'
import { Overview } from '../overview/overview.component'
import { Questions } from '../questions/questions.component'
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { ProductTestimonials } from '../productDetail/productTestimonials/productTestimonials.component'
import { UserContext } from '../../contexts/user.context'
import { ReactComponent as BloodSvg} from "../../assets/svg/blood_drop.svg"
import { ReactComponent as CTSvg} from "../../assets/svg/tac.svg"
import { ReactComponent as DrSvg} from "../../assets/svg/doctor-3.svg"
import { addSlack } from "../../services/slack.service"
import { PopupModal } from "react-calendly";
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import MembershipTypeCode from '../../enums/membershipTypeCode.enum'
import { MembershipPlan } from '../membershipPlan/membershipPlan.component'
import { ReactComponent as FoodSvg} from "../../assets/svg/food-utensils.svg"
import { ResearchCard } from '../researchCard/researchCard.component'
import { BlogData } from '../../data/blog.data'
import { DownOutlined, BellFilled, ArrowRightOutlined } from '@ant-design/icons';
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";


export const Home = () => {
  const navigate = useNavigate()
  const testingRef = useRef(null)
  const membershipRef = useRef(null)
  const { currentUser } = useContext(UserContext)
  const [openCalendly, setOpenCalendly] = useState()  
  const [membership, setMembership] = useState();
  const [hhAddOn, setHHAddOn] = useState();
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const membershipData = await fetchMembership();
      const hhAddOnData = await fetchHHAddOn();
      
      setMembership(membershipData);
      setHHAddOn(hhAddOnData);
    };
  
    fetchData();
  }, []);
  

  const fetchMembership = async () => {
    return await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY_12MO);
  }

  const fetchHHAddOn = async () => {
    return await getMembershipTypeByCode(MembershipTypeCode.HEART_HEALTH);
  }

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_HOME_V2)
  }

  const onChat = () => {
    window.FrontChat("show");
  };

  const onQuiz = () => {
    navigate(`/start`)
  }

  const getStarted = async () => {
    try {
      await addSlack({ 
        message: 'User Clicked HOME - Main CTA',
        channel: process.env.REACT_APP_PATIENT_SLACK
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate(`/start`)
    // navigate(`/longevity-panel`)
  }


  const scrollToTests = () => {
    testingRef.current?.scrollIntoView({ behavior: 'smooth' });
  }



  const testimonials = [
    {
      name: "Gustaf Alstromer",
      title: "Partner @ Y Combinator",
      image: "/img/gustaf.jpg",
      webp: "/img/gustaf.webp",
      text: "Staying on top of your health is a must for the best founders. Instalab gave me a clear picture of where I stand and what to focus on. It’s an invaluable tool for anyone serious about improving their health and longevity."
    },
    {
      name: "Jason Goldman",
      title: "Filmmaker @ Wild Growth Media",
      image: "/img/jason.jpg",
      webp: "/img/jason.webp",
      text: "Thanks to Instalab, I discovered I had early osteoporosis. Their team guided me through testing and treatment, helping me uncover the source: celiac disease. I feel like I finally have control over my health."

    }, 
    {
        name: "Ed Suh",
        title: "MD @ Alpine Ventures ",
        image: "/img/ed.jpg", 
        webp: "/img/ed.webp",
        text: "Instalab’s comprehensive approach blew me away. The reports focus on the issues that matter most to me, with intuitive summaries and clear explanations of where I stand on the risk curve."
    },
    {
      name: "Kat Manalac",
      title: "Partner @ Y Combinator",
      image: "/img/kat.jpg",
      webp: "/img/kat.webp",
      text: "As a working parent, I struggle to make time for my health. Instalab made it simple. Their labs gave me clear, actionable insights and a game plan that fits into my busy life. I finally feel proactive about my health."
  },

  ]

  const faqs = [
    {
      question: "Why choose Instalab for my health needs?",
      answer: <>Instalab simplifies proactive health care by combining expert-guided testing, tailored recommendations, and physician consultations. You can choose <a onClick={scrollToTests} className="cta-link">à la carte options</a> or opt for our comprehensive <a href={`/flow/${FlowType.MEMBER}`} className="cta-link">all-in-one membership</a> to take charge of your long-term health with ease.</>,
    },  
    {
      question: "What makes Instalab different from traditional health services?",
      answer: `Unlike traditional healthcare, Instalab focuses on prevention and long-term health optimization. We offer advanced testing, expert-guided plans, and concierge-level support to keep you ahead of potential health risks.`,
    },
    {
      question: "What tests should I be doing to stay ahead of health risks?",
      answer: <>Your health is unique, so your tests should be too. Instalab uses your medical history, genetics, and lifestyle to recommend the most relevant tests. <a onClick={() => onQuiz()} className="cta-link">Take our quiz</a> to discover the right tests for you.</>,
    },   
    {
      question: "How does Instalab help me improve my health?",
      answer: `Instalab goes beyond routine testing by providing personalized insights, consultations with longevity physicians, and tailored game plans to address your specific health goals. It's about empowering you to take control of your health.`,
    },
    {
      question: "What happens after I get my test results?",
      answer: `Your results are reviewed by our physicians, and you'll receive a detailed breakdown along with actionable steps to improve your health. You can also schedule a consultation to discuss the findings in-depth.`,
    },
    {
      question: "How do I use my HSA/FSA with Instalab?",
      answer: `Instalab is fully eligible for HSA/FSA reimbursement. Use your HSA/FSA card at checkout, or if you prefer, we can provide an itemized receipt to submit for reimbursement.`,
    },
    {
      question: "What is covered by my HSA/FSA?",
      answer: `All Instalab services—including tests, memberships, and physician consultations—are covered by your HSA/FSA, making it a convenient way to prioritize your health.`,
    },
    {
      question: "Does my health insurance cover Instalab?",
      answer: `Insurance does not cover Instalab services. However, you can use your HSA/FSA for reimbursement, making it a flexible option to invest in your health.`,
    },
    {
      question: `Can Instalab replace my primary care provider?`,
      answer: `Instalab complements, rather than replaces, your primary care provider. Our focus is on prevention and long-term health optimization. We encourage maintaining your existing provider relationship for routine and emergency care while we handle proactive health planning.`,
    }, 
    {
      question: "What makes Instalab physicians unique?",
      answer: <>At Instalab, each of our physicians is board-certified in various specialties and brings a wealth of experience in guiding patients toward their health goals. They stay current with the latest research and are not just experts but also skilled listeners. Our physicians strive to understand your unique situation and collaborate with you to ensure that your care is aligned with your individual goals and preferences.</>,
    },
    {
      question: "How often should I retest?",
      answer: `Retesting depends on your specific health goals and test results. We will recommend a schedule based on your results and goals.`,
    },
    {
      question: "Can I request tests not currently in your catalog?",
      answer: <>Yes, we aim to meet your specific needs. Email us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a> with your request, and we’ll work to make it happen.</>,
    },
    {
      question: "Is Instalab available in my area?",
      answer: <>Instalab is available anywhere in the United States, so you can access our services from wherever you are.</>,
    },
    {
      question: "Can my company cover the cost of Instalab?",
      answer: `Absolutely! Many companies view health services like Instalab as an investment in their team. If you'd like, we can help communicate the value of our services to your employer.`,
    },
    {
      question: "Is my health data secure?",
      answer: `Your privacy is our priority. We use state-of-the-art encryption and comply with strict data protection standards to keep your information safe. We will never sell your data to third parties.`,
    },
    {
      question: "Can I gift an Instalab to someone I care about?",
      answer: <>Yes! Instalab makes a thoughtful and impactful gift. <a className="cta-link" onClick={() => navigate('/flow/gift')}>Fill out the recipient's info</a>, and we’ll send a custom digital e-card on the date you choose.</>,
    },
]

  const getLatestResearch = () => {
    return BlogData.slice(0, 3);
  };

  const MembershipPlans = () => {

    return (
      <Row gutter={36} className="membership-plans-container">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <MembershipPlan
            membershipTypeCode={MembershipTypeCode.LONGEVITY_12MO}
            path={`/flow/${FlowType.MEMBER}`}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >

          <MembershipPlan
            membershipTypeCode={MembershipTypeCode.HEART_HEALTH}
            path={`/heart`}
          />
      
        </Col>
   

      </Row>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.introduction');
      if (heroSection) {
        const heroSectionHeight = heroSection.offsetHeight;
        const scrollPosition = window.scrollY;
        setShowGetStarted(scrollPosition > heroSectionHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const LongevityPlanSteps = () => {
    return (
      <div className="">
        <Overview title="How to Make the Most of Instalab"/>
        <div className="longevity-plan-steps">
          <div className="step">
            <div className="step-image-wrapper">
              <img src="/img/how-it-works-1.webp" alt="Step 1" className="step-image" />
            </div>
            <div className="step-content">
              <div className="step-number">01</div>
              <h3>Review your annual testing roadmap</h3>
              <p>We design a custom plan tailored to your medical history, genetics, and lifestyle to help you stay proactive about your health.</p>
            </div>
          </div>
          <div className="step">
            <div className="step-image-wrapper">
              <img src="/img/how-it-works-2.webp" alt="Step 2" className="step-image" />
            </div>
            <div className="step-content">
              <div className="step-number">02</div>
              <h3>Complete your tests</h3>
              <p>Have your samples conveniently collected at home or visit trusted partner centers for tests and scans requiring in-person visits.</p>
            </div>
          </div>
          <div className="step">
            <div className="step-image-wrapper">
              <img src="/img/how-it-works-3.webp" alt="Step 3" className="step-image" />
            </div>
            <div className="step-content">
              <div className="step-number">03</div>
              <h3>Get personalized game plan</h3>
              <p>Your results power a targeted strategy to improve your health with lifestyle adjustments, supplements, and medication recommendations.</p>
            </div>
          </div>
          <div className="step">
            <div className="step-image-wrapper">
              <img src="/img/how-it-works-4.webp" alt="Step 4" className="step-image" />
            </div>
            <div className="step-content">
              <div className="step-number">04</div>
              <h3>Consult with Instalab physician</h3>
              <p>Members can connect with a dedicated physician to review results, address questions, and refine their plan.</p>
            </div>
          </div>
          <div className="step">
            <div className="step-image-wrapper">
              <img src="/img/how-it-works-5.webp" alt="Step 5" className="step-image" />
            </div>
            <div className="step-content">
              <div className="step-number">05</div>
              <h3>Ongoing testing and care</h3>
              <p>Track progress with follow-up testing and evolving strategies designed to keep your health on track.</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {membership && <div className="home">
        <PopupModal
          open={openCalendly}
          url="https://calendly.com/d/cmhv-xmp-7h4?hide_gdpr_banner=1"
          onModalClose={() => setOpenCalendly(false)}
          rootElement={document.getElementById("root")}
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
          }}
        />
        
        <SubscribeModal
          open={openSubscribe}
          setOpen={setOpenSubscribe}
        />

        <div className="introduction">
          <div className="max-width">
            <Row 
              gutter={48}
              className="introduction-row"
            >
              <Col 
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                className="introduction-main-col"
              >
                <div className="introduction-title">
                  Proactive Health<br/>Starts Here.
                </div>

                <div className="introduction-description">
                The best advanced diagnostics and personalized care to detect disease early and help you live longer.     
                {/* Get your personalized testing roadmap to catch diseases early and stay healthier longer.  */}
                </div>

                <Button
                  className="join-btn"
                  type="primary"
                  onClick={getStarted}
                >
                 {/* Start with Longevity Panel */}
                 {/* Get My Testing Roadmap */}
                 Get Started
                </Button>

                <Button
                  className="learn-btn"
                  type="default"
                  onClick={scrollToTests}
                >
                  Browse Tests
                </Button>


              </Col>
            </Row>
          </div>
        </div>

        <div className="stats-section dark-bg">
          <Row justify="center" align="middle" className="max-width">
            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-number highlight">FSA/HSA</div>
                <div className="metric-label">Accepted</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-number highlight">18,000+</div>
                <div className="metric-label">Patients Treated</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-number highlight">100+</div>
                <div className="metric-label">Corporate Clients</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-number highlight">4.9/5</div>
                <div className="metric-label">Patient Rating</div>
              </div>
            </Col>
          </Row>
        </div>


        <div className="testing" ref={testingRef}>

          <Overview 
            title="Popular Tests"
            description={<>Explore the most effective ways to early detect conditions like heart disease and cancer.</>}
          />
            
          <div className="test-catalog-container">
          <TestCatalog/>
          </div>

        </div>

        <div className="longevity-plan-section dark-bg">
          <LongevityPlanSteps/>
        </div>


        <div className="testimonials">
          <Overview title="Hear From Real Patients"/>
          <div className="testimonials-container">
            <ProductTestimonials testimonials={testimonials}/>
          </div>
        </div>



        <div className="faq dark-bg">
          <Overview
            title="More About Instalab"
            description={<>If you have any questions, <a onClick={onChat}>we'd love to chat with you</a>.
          
            </>}
          />
          <Questions items={faqs}/>
        </div>

        <div className="research-section section dark-bg">
          <Overview 
            title="Instalab Research"
            description="Learn actionable insights from the latest medical research that could help you live longer."
          />

          <div className="research-container">
            <Row gutter={[24, 24]}>
              {getLatestResearch().map(({ id, title, description, publishedAt, readTime }) => (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} key={id}>
                  <ResearchCard
                    title={title}
                    description={description}
                    readTime={readTime}
                    publishedAt={publishedAt}
                    onClick={() => navigate(`/research/${id}`)}
                  />
                </Col>
              ))}
            </Row>
            <div className="view-more-wrapper">
              <Button
                type="primary"
                className="subscribe-btn"
                icon={<BellFilled />}
                onClick={() => {
                  setOpenSubscribe(true)
                  PosthogHelper.track(currentUser, '[research] click subscribe btn', true)
                }}
              >
                Subscribe
              </Button>
              <Button type="default" className="view-btn" onClick={() => navigate('/research')}>
                View More  <DownOutlined className="view-btn-icon" />
              </Button>
            </div>
          </div>
        </div>


        {showGetStarted && (
          <div className="get-started-section">
            <div 
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 1000
              }}
            >
              <Button
                type="primary"
                className="get-started-btn"
                onClick={getStarted}
              >
                Get Started <ArrowRightOutlined style={{ marginLeft: '8px' }} />
              </Button>
            </div>
          </div>
        )}
  
        <PgFooter />
 
      </div>}
    </>
  )
}
