import { useContext, useEffect, useRef, useState } from 'react'
import { FlowStep } from "./flowStep/flowStep.component"
import { Form, Button, Progress, Affix, Spin, Typography, message } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import { UserContext } from '../../contexts/user.context'
import Breakpoint from '../../enums/breakpoint.enum'
import { useNavigate } from 'react-router-dom'
import Color from '../../colors.scss'
import { addMembership } from "../../services/membership.service"
import { addProduct } from "../../services/product.service"
import {
  UpOutlined,
  DownOutlined,
  MessageFilled,
} from '@ant-design/icons'
import './flow.scss'
import FormHelper from './helpers/form.helper'
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure'
import StepType from './enums/stepType.enum';
import PosthogHelper from '../../helpers/posthog.helper';
import { getFlow, updateFlow } from '../../services/flow.service'
import { addCalendlyConsult } from '../../services/consult.service'
import MembershipTypeCode from '../../enums/membershipTypeCode.enum'
import MembershipStatus from '../../enums/membershipStatus.enum'
import FlowType from '../../enums/flowType.enum'
import {
  setDefaults,
  fromAddress,
} from "react-geocode";
import { addAppointment, addAppointmentInvites, updateAppointment, addPendingAppointment, joinAppointment } from '../../services/appointment.service'
import PlanCode from '../../enums/planCode.enum'
import { addCard, listCards } from '../../services/card.service'
import ProductTypeCode from '../../enums/productTypeCode.enum'
import { buildUser } from '../../services/user.service'
import useWidth from '../../hooks/useWidth.hook'
import StorageKey from '../../enums/storageKey.enum'
import Role from '../../enums/role.enum'
import { listMemberships } from '../../services/membership.service'
import MembershipHelper from '../../helpers/membership.helper'
import { getProductTypeByCode } from '../../services/productType.service'

const { Text } = Typography

const membershipSelect = '_id status startAt endAt monthlyPrice price'
const membershipPopulate = [{
  path: 'membershipType',
  select: '_id code isAnnual'
}]

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

export const Flow = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe, elements}) => <>
          <FlowChild
            stripe={stripe}
            elements={elements}
            {...props}
          />
        </>}
      </ElementsConsumer>
    </Elements>
  )
}

const FlowChild = ({ 
  skeleton, 
  stripe, 
  elements, 
  flow, 
  setFlow, 
  startIndex=0,
  initialUser,
  setInitialUser,
  setHasPass,
  productTypeCode,
}) => {
  const pageLocation = useLocation()
  const width = useWidth()
  const { 
    flowId,
    code, 
    flowType, 
  } = useParams()


  const navigate = useNavigate()
  const location = useLocation()


  const { 
    token,
    setToken,
    currentUser,
    setCurrentUser,
    setInstalabMembership,
    instalabMembership,
    setMemberships,
  } = useContext(UserContext)


  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [isSubmittingPayment, setIsSubmittingPayment] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const [userSelect, setUserSelect] = useState('')
  const [cards, setCards] = useState()
  const [activeCard, setActiveCard] = useState(null) // get active card
  const [step, setStep] = useState()
  const stepRef = useRef(null)
  stepRef.current = step

  const [predictedRoute, setPredictedRoute] = useState([])
  const predictedRouteRef = useRef()
  predictedRouteRef.current = predictedRoute

  const flowRef = useRef()
  flowRef.current = flow

  useEffect(() => {
    resetInitialUser()
    fetchFlow()
    fetchHasPass()
  }, [flowId])

  useEffect(() => {
    fetchUserSelect()
  }, [flow])

  useEffect(() => {
    fetchInitialUser()
  }, [flow, token])

  useEffect(() => {
    fetchCards()
  }, [skeleton, currentUser])

  useEffect(() => {
    onStepLoad()
  }, [step])

  useEffect(() => {
    redirectToFirstStep()
  }, [code, skeleton])


  const fetchHasPass = () => {
    if (setHasPass) {
      setHasPass(token ? true : false)
    }
  }

  const redirectToFirstStep = () => {
    if (code !== 'step' || !skeleton) return
    const url = `${getUrlPrefix()}${flowType}/${getFirstStepCode()}/${flowId || flowRef?.current?._id}`
    navigate(url, { replace: true })
  }

  const fetchCards = async () => {
    if (!skeleton || !currentUser) return

    const hasPaymentStep = Object.values(skeleton).some(({ type }) => type === StepType.PAY)
    if (!hasPaymentStep) return

    let params = {
      flow: flowId,
      filter: {
        isRemoved: {
          $ne: true
        }
      },
      select: '_id brand last4',
      populate: [],
    }

    setCards(await listCards(params))
  }

  const onStepLoad = async () => {
    if (step?.onLoad) {
      await step.onLoad()
    }
  }

  const resetInitialUser = () => {
    setInitialUser(null)
  }

  const fetchInitialUser = async () => {
    if (flow && !initialUser) {
      setInitialUser(flow.user)
    }
  }

  useEffect(() => {
    fetchStepByCode()
  }, [code, skeleton])

  useEffect(() => {
    trackPosthog()
  }, [flow, code])

  useEffect(() => {
    setHasAttempt(false)
  }, [step])

  useEffect(() => {
    fetchFormValue()
  }, [step, flow])

  useEffect(() => {
    fetchPredictedRoute()
  }, [step, skeleton, initialUser, token])


  
  const fetchFlow = async () => {
    if (flowId) {
      const flowData = await getFlow(flowId)
      setFlow({
        ...flowData,
        // prefersMobileDraw: true,
      })
    }
  }

  const getFirstStepCode = () => {
    return Object.keys(skeleton).filter(key => !skeleton[key].skipIf || !skeleton[key].skipIf())[0]
  }

  const trackPosthog = () => {
    if (!flow || !code) return
    PosthogHelper.track(currentUser, `[${flow?.medication?.code || flowType}] ${code}`)
  }

  const fetchPredictedRoute = async () => {
    if (!step  || !skeleton) return
    const shouldHaveUser = token || localStorage.getItem(StorageKey.TRACKER)

    if (shouldHaveUser && !initialUser) return

    let route = [getFirstStepCode()]
    let hasNextStep = true

    while (hasNextStep) {
      const lastStepCode = route[route.length - 1]
      let lastStep = skeleton[lastStepCode]
      let nextStepCode = await getNextStepCode(lastStep)
      let nextStep = nextStepCode ? skeleton[nextStepCode] : null

      if (nextStep) {
        // Skip if user value already exists when component initialized
        while (nextStep?.skipIf && nextStep.skipIf()) {
          nextStepCode = await getNextStepCode(nextStep)

          nextStep = nextStepCode ? skeleton[nextStepCode] : null
        }

        if (nextStep) {
          route.push(nextStepCode)
        } else {
          hasNextStep = false
        }
      } else {
        hasNextStep = false
      }
    }
    setPredictedRoute(route)
  }

  const fetchStepByCode = () => {
    if (!code || !skeleton || !skeleton[code]) return
    setStep(skeleton[code])
  }

  const fetchFormValue = () => {
    if (!step || step?.type === StepType.STATIC) return
    if (!step.field && !step.fields?.length) return
    if (step.buildUser && !flow?.user) return
    if (step.buildFlow && !flow) return

    const fields = step.fields ? step.fields : [step.field]
    let fieldNames = fields.map(({ name }) => name)
    for (const field of fields) {
      if (field.suffixField) {
        fieldNames.push(field.suffixField.name)
      }
    }

    for (const fieldName of fieldNames) {
      const formObject = getFormObject()
      const hasFlatValue = fieldName in formObject
      const hasNestedValue = fieldName.includes('.') && formObject[fieldName.split('.')[0]]  && formObject[fieldName.split('.')[0]][fieldName.split('.')[1]] !== undefined

      let formValue = null
      let params = {}
      const initialValue = step.field?.initialValue
      

      // Fetch value from flow or user object

      if (hasFlatValue || hasNestedValue) {
        if (hasNestedValue) {
          const fieldNames = fieldName.split('.')
          formValue = formObject[fieldNames[0]][fieldNames[1]]
        } else {
          formValue = formObject[fieldName]
        }
      }


      switch (step.type) {
        case StepType.SINGLE_SELECT:
          if (!formValue && typeof formValue !== 'boolean') {
            formValue = initialValue
          }
          if (formValue?._id) {
            formValue = formValue._id
          }
          // If the select does not have the formValue, do not set it
          if (step.field?.options?.length && step.field?.options?.some(({ value }) => formValue === value)) {
            params = { [fieldName]: [formValue] }
          }
          break
        case StepType.FACILITY:
          params = { [fieldName]: [formValue || initialValue] }
          break
        case StepType.TEXTAREA:
        case StepType.MULTIPLE_INPUT:
          params = { [fieldName]: formValue || initialValue }
          break
        case StepType.LOCATION:
          params = formValue
          break
        case StepType.PRODUCT_SELECT:
          if (step.multiple) {
            params = { [fieldName]: formValue || initialValue }
          } else {
            params = { [fieldName]: [formValue || initialValue] }
          }
          break
        default:
          params = { [fieldName]: formValue || initialValue }
          break
      }

      form.setFieldsValue(params)
    } 
  }

  const getFormObject = () => {

    if (step.buildUser) {
      return flow?.user
    } else if (step.buildFlow) {
      return flow
    } else if (step.buildAppointment) {
      return flow?.appointment || {}
    }
  }

  const getNextStepCode = async (lastStep) => {
    try {
      if (!lastStep) {
        return null;
      }

      // If there's a dynamic onNextStep function, execute it
      if (lastStep.onNextStep) {
        const nextStep = await lastStep.onNextStep(flow?.user, flow);
        return nextStep;
      }
      
      // If there's a static nextStep property, return it
      if (lastStep.nextStep) {
        return lastStep.nextStep;
      }
            
      return null;
    } catch (error) {
      console.error('Error determining next step:', error);
      return null;
    }
  }

  const getNextStep = async (nextUser, updatedFlow) => {
    nextUser = nextUser || flow?.user;
    let currentStep = stepRef.current;
    
    // Initialize next step variables
    let nextStepCode = currentStep.nextStep || (currentStep.onNextStep && await currentStep.onNextStep(nextUser, updatedFlow));
    let nextStep = skeleton[nextStepCode];
    
    // Process steps and skip as necessary, without awaiting in the main loop
    while (nextStep?.skipIf) {
      // SkipIf should be evaluated asynchronously, but not awaited if not necessary
      const shouldSkip = await nextStep.skipIf(updatedFlow);
      if (!shouldSkip) {
        break; // No need to skip, continue to next step
      }
      nextStepCode = nextStep.nextStep || (nextStep.onNextStep && await nextStep.onNextStep(nextUser, updatedFlow));
      nextStep = skeleton[nextStepCode];
    }
  
    if (nextStepCode) {
      // Prevent rendering flicker by setting the step only once fully determined
      return `${getUrlPrefix()}${flowType}/${nextStepCode}/${flowId || flowRef?.current?._id}`;
    }
  
    return null; // No next step
  };

  const onNextStep = async (nextUser, updatedFlow) => {
    const nextUrl = await getNextStep(nextUser, updatedFlow)
    if (nextUrl) {
      navigate(nextUrl)
    }
  }

  const onPrevStep = () => {
    const currentIndex = predictedRouteRef.current.findIndex(c => c === code)
    const prevCode = predictedRouteRef.current[currentIndex - 1]
    if (prevCode === undefined || prevCode === 'step') {
      navigate('/')
    } else {
      navigate(`${getUrlPrefix()}${flowType}/${prevCode}/${flowId || flow?._id}`)
    }
  }

  const getUrlPrefix = () => {
    if (pageLocation.pathname.includes('/provider-flow')) {
      return `/provider-flow/`
    } else if (pageLocation.pathname.includes('/pro-flow')) {
      return `/pro-flow/`
    } else { 
      return '/flow/'
    }
  }

  const fetchUserSelect = () => {
    if (!skeleton) return
    let fields = Object.values(skeleton).reduce((acc, { field, fields }) => {
      if (field) {
        acc.push(field.name)
      } else if (fields) {
        acc = [
          ...acc,
          ...fields.map(({ name }) => name)
        ]
      }
      return acc
    }, [])
    const hasSchedule = Object.values(skeleton).some(({ type }) => type === StepType.SCHEDULE)
    if (hasSchedule) {
      fields.push('phlebType')
    }

    const hasPaymentStep = Object.values(skeleton).some(({ type }) => type === StepType.PAY)
    if (hasPaymentStep) fields.push('cards')

    fields = fields.filter(field => field !== 'password')
    setUserSelect([...new Set(fields)].join(' '))
  }

  const onSubmit = async (
    componentValues=null, 
    shouldContinue=true,
    skipValidation=false
  ) => {

    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true)
    setHasAttempt(true)

    let error = skipValidation ? null : await FormHelper.fetchHasError(form)
    if (error) {
      setIsSubmitting(false)
      return
    }

    try {
      let values = componentValues || form.getFieldsValue() 

      // Skip password step if field's empty, but user is already logged in
      const isEmptyPass = step.buildUser && token && step.field?.password && !values[step.field.name]

      let newUser, response;
      if (step.buildUser && !isEmptyPass) {
        response = await onBuildUser(values)
        newUser = response.user
      }

      if (step.buildFlow) {
        response = await onBuildFlow(values)
      }

      if (step.buildAppointment) {
        response = await onBuildAppointment(values)
      }

      // Always build user first in case future functions depend on user fields
      if (step.type === StepType.UPSELL && !step?.hasPurchased) {
        await step.onUpsell(newUser)
      }

      if (step.addLongevityMembership && flowRef?.current?.addLongevityMembership) {
        await onSubmitPayment(values)
        await PosthogHelper.track(currentUser, `subscribed: instalab membership`)
      }

      if (step.addSubscription) {
        await onSubmitPayment(values)
        await PosthogHelper.track(currentUser, `subscribed: ${step.subscriptionType}`)
      }
      
      if (step.addProduct && !flowRef?.current?.addLongevityMembership) {
        response = await onSubmitPayment(values)
        await PosthogHelper.track(currentUser, `paid: ${step.productType}`)
      }
      
      if (step.addCard) {
        await onSubmitCard()
      }
      
      if (step.addAppointment) {
        await onAddAppointment(values)
      }

      if (step.addPendingAppointment) {
        await onAddPendingAppointment(values)
      }

      if (step.addInvite) {
        await onAddInvite(values)
      }
      
      if (step.addConsult) {
        await onSubmitConsult(values)
      }

      if (step.addFreeProduct) {
        await onSubmitFreeProduct()
      }

      if (step.onSuccess) await step.onSuccess(newUser)
      if (shouldContinue) {
        await onNextStep(newUser, response?.flow || flow)
      }
    } catch (err) {
      if (err.response?.data?.code === 11000) {
        message.error('Account already exists with this email')
      }
    } finally {
      setIsSubmitting(false)

    }
  }

  const onBuildFlow = async (values) => {
    values = await formatValues(values)
    const response = await updateFlow(flowId || flow?._id, values)
    await setFlow(response)
    return { flow: response }
  }


  const onAddInvite = async (values) => {
    let appointment = flow?.appointment

    const appointmentSelect = '_id start location phlebType status invitePrice patients orders'

    // Filter out undefined values and get valid inviteIds
    const inviteIds = Object.entries(values)
      .filter(([key, value]) => key.startsWith('inviteOption') && value !== undefined && value !== "")
      .map(([_, value]) => value);


    if (inviteIds.length > 0) {
      for (const inviteId of inviteIds) {
        const response = await joinAppointment(flow.appointment._id, { userId: inviteId })
        appointment = response.appointment
      }
    }

    // Handle SMS invites if present
    const phones = Object.values(values)
      .filter(phone => phone?.replace(/\D/g,'')?.length === 10)

    if (phones.length > 0) {
      appointment = await addAppointmentInvites(flow.appointment._id, {
        fields: {
          phones,
        },
        select: appointmentSelect,
        populate: [{
          path: 'invites',
          select: '_id phone status'
        }]
      })
    }

    if (appointment) {
      setFlow(cachedFlow => {
        return {
          ...cachedFlow,
          appointment
        }
      })
    }
  }

  const onAddAppointment = async (values) => {
    const { scheduleTime } = await formatValues(values)
    
    const select = '_id start location phlebType status invitePrice patients facility orders invites phlebotomist gCalId gCalOff'
    const populate = [{
      path: 'invites',
      select: '_id phone status'
    }, {
      path: 'phlebotomist',
      seelct: '_id firstName lastName'
    },
    {
      path: 'facility'
    }]
    let appointment

    if (flow?.appointment) {
      const hasTimeChanged = new Date(flow.appointment.start).getTime() !== scheduleTime
      if (hasTimeChanged) {
        appointment = await updateAppointment(flow.appointment._id, { 
          fields: {
            start: scheduleTime 
          },
          select,
          populate,
        })


        // Check if the appointment service returns an error message
        if (appointment?.message) {
          message.error(appointment.message);
          throw new Error(appointment.message);
      }
      }
    } else {
      appointment = await addAppointment({ 
        flow: flowId || flow?._id,
        fields: {
          start: scheduleTime,
          type: flow?.type,
          facility: flow?.facility,
          prefersMobileDraw: flow?.prefersMobileDraw
        },
        select,
        populate,
      })

      // Check if the appointment service returns an error message
      if (appointment?.message) {
        message.error(appointment.message);
        throw new Error(appointment.message);
      }
    }

    if (appointment) {
      setFlow(cachedFlow => {
        return { 
          ...cachedFlow, 
          appointment 
        }
      })
    }

  }

  const onAddPendingAppointment = async (values) => {
    const { scheduleTime } = await formatValues(values)
    
    const select = '_id start location phlebType status invitePrice patients facility updatedAt'
    const populate = [{
      path: 'invites',
      select: '_id phone status'
    }, {
      path: 'phlebotomist',
      seelct: '_id firstName lastName'
    },
    {
      path: 'facility'
    }]
    let appointment

    if (flow?.appointment) {
      const hasTimeChanged = new Date(flow.appointment.start).getTime() !== scheduleTime
      if (hasTimeChanged) {
        appointment = await updateAppointment(flow.appointment._id, { 
          fields: {
            start: scheduleTime 
          },
          select,
          populate,
        })

        // Check if the appointment service returns an error message
        if (appointment?.message) {
          message.error(appointment.message);
          throw new Error(appointment.message);
      }
      }
    } else {
      appointment = await addPendingAppointment({ 
        flow: flowId || flow?._id,
        fields: {
          start: scheduleTime,
          type: flow?.type,
          facility: flow?.facility,
          prefersMobileDraw: flow?.prefersMobileDraw
        },
        select,
        populate,
      })

      // Check if the appointment service returns an error message
      if (appointment?.message) {
        message.error(appointment.message);
        throw new Error(appointment.message);
      }
    }

    if (appointment) {
      setFlow(cachedFlow => {
        return { 
          ...cachedFlow, 
          appointment 
        }
      })
    }

  }

  const onBuildUser = async (values) => {
    if (step.hiddenFields?.length) {
      for (const field of step.hiddenFields) {
        values[field.name] = field.value
      }
    }

    values = await formatValues(values)

    if (currentUser?.role === Role.PROVIDER || currentUser?.role === Role.ADMIN) {
      values.patient = flow?.user?._id
    }

    // if athlete, tag user as such
    if (flow?.type === FlowType.ATHLETE_TEST) {
      values['isAthlete'] = true
    }

    const response = await buildUser({
      flow: flowId,
      fields: values,
      select: userSelect,
      populate: []
    })

    if (response.token) {
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
    }
    setFlow({...flow, user: response.user })
    return response
  }

  const onBuildAppointment = async (values) => {
    values = await formatValues(values)
    const response = await updateAppointment(flow?.appointment?._id, { fields: values })
    setFlow({...flow, appointment: response})
    return response
  }

  const formatValues = async (values) => {

    console.log('values', values)
    switch (step.type) {
      case StepType.SINGLE_SELECT:
        return {
          [Object.keys(values)[0]]: Object.values(values)[0][0]
        }
      case StepType.PRODUCT_SELECT:
        if (step.multiple) {
          return values
        } else {
          return {
            [Object.keys(values)[0]]: Object.values(values)[0][0]
          }
        }
      case StepType.FACILITY:
          if (step.multiple) {
            return values
          } else {
            return {
              [Object.keys(values)[0]]: Object.values(values)[0][0]
            }
          }
      case StepType.SCHEDULE:
        return {
          ...values,
          scheduleDate: values.scheduleDate[0],
          scheduleTime: values.scheduleTime[0],
        }
      case StepType.LOCATION:
        const { streetAddress, city, state, postalCode } = values;

        // Combine full address
        const fullAddress = `${streetAddress}, ${city}, ${state}, ${postalCode}, USA`;

        // Fetch latitude and longitude based on full address
        const { results } = await fromAddress(fullAddress);
    
        const { 
          lat: latitude, 
          lng: longitude,
        } = results[0].geometry.location;
        values = {
          [step.field.name]: {
            ...values,
            latitude,
            longitude
          }
        }
        return values
      default:
        return values
    }
  }

  const onSubmitConsult = async (values) => {
    try {
      const consultResponse = await addCalendlyConsult({
        flowId: flowId || flow?._id,
        eventUri: values.eventUri,
        inviteeUri: values.inviteeUri
      })

      setFlow(consultResponse.flow)
    } catch (err) {
      const error = err?.response?.data?.err || 'Unable to schedule appointment'
      message.error(error)
      throw new Error(error)
    }
  }

  const getCardParams = async () => {
    //if (hasCredit) return {}

    const cardElement = elements.getElement(CardElement)
    if (!cardElement) return {}

    const stripeResponse = await stripe.createSource(cardElement, { type: 'card' })
    const { error, source } = stripeResponse

    if (error) {
      message.error(error.message)
      throw new Error(error.message)
    }


    const {
      exp_month: expMonth,
      exp_year: expYear,
      country,
      last4,
      brand
    } = source.card

    return {
      stripeId: source.id,
      expMonth,
      expYear,
      country,
      last4,
      brand,
      patient: flow.user._id
    }
  }

  const onAddLongevityMembership = async () => {
    let params = await getCardParams()
    params.type = MembershipTypeCode.LONGEVITY

    return await addMembership({
      fields: params,
      select: membershipSelect,
      populate: membershipPopulate
    })
    
  }

  const onSubmitFreeProduct = async () => {

    if (flow.products?.length && !flow[step.productField]) return 

    let params = { 
      flowId,
      patientFields: step.patientFields,
      free: true
    }
    
    try {
      let productTypes = step.productType || flow[step.productField]
      if (!Array.isArray(productTypes)) {
        productTypes = [productTypes]
      }

      for (const productType of productTypes) {
        params.type = productType
        await addProduct({
          fields: params,
        });
      }
    } catch (err) {
      const error = err?.response?.data?.err || 'Something went wrong - try again or contact concierge@instalab.com for help'
      message.error(error)
      throw new Error(error)
    }
  }

  const getHasPurchased = () => {
    if (!step || !flow) return false
    if (step.addSubscription) {
      return flow.membership?.membershipType?.code === step.subscriptionType && flow.membership?.status === MembershipStatus.ACTIVE
    }
    if (step.addProduct) {
      return flow.products?.some(product => step.productType === product.productType?.code)
    }
    return false
  }

  const onSubmitCard = async () => {
    if (cards?.length) return
    try {
      const fields = await getCardParams()
      const card = await addCard({
        fields,
        select: '_id last4 brand stripeId',
      })
      if (cards?.length) {
        setCards([...cards, card])
      }
    } catch (err) {
      const error = err?.response?.data?.err || 'Unable to save card'
      message.error(error)
      throw new Error(error)
    }
  }

  const onSubmitPayment = async (activeCard) => {    
    const hasPurchased = getHasPurchased()
    if (hasPurchased || isSubmittingPayment) return
    setIsSubmittingPayment(true)

    try {
      let params = { 
        flowId: flowId || flow?._id || flowRef?.current?._id,
        patientFields: step.patientFields,
      }
  
      let newInstalabMembership, response;
      
      if (flowRef?.current?.addLongevityMembership  && step.addLongevityMembership && !instalabMembership) {
        response = await onAddLongevityMembership()
        newInstalabMembership = response.membership
      } else {
        const cardParams = await getCardParams()
        params = { ...params, ...cardParams }
      }

      
      if (step.addSubscription) {
        params.type = step.subscriptionType
        params.patient = flow?.user?._id
        if (step.subscriptionTrialDays) {
          params.trialDays = step.subscriptionTrialDays
        }

        if (activeCard) {
          params.card = activeCard._id
          params.stripeId = activeCard.stripeId
        }

        response = await addMembership({
          fields: params,
          select: membershipSelect,
          populate: membershipPopulate,
        })

        // update new memberships everywhere
        const memberships = await listMemberships({populate: membershipPopulate})
        newInstalabMembership = MembershipHelper.getActiveInstalabMembership(memberships)
        setInstalabMembership(newInstalabMembership)
        setMemberships(memberships)

      }
      


      if (step.addProduct) {
  
        const productTypes = Array.isArray(step.productType) ? step.productType : [step.productType]        

        if (activeCard) {
          params.card = activeCard._id
          params.stripeId = activeCard.stripeId
        }

  
        const hasAppointment = productTypes.some(productType => [
          ProductTypeCode.MOBILE_BLOOD_DRAW, 
          ProductTypeCode.PRO_MOBILE_BLOOD_DRAW, 
          ProductTypeCode.LAB_VISIT].includes(productType))

        const hasKit = productTypes.some(productType => [ProductTypeCode.TASSO].includes(productType))


        for (const productType of productTypes) {
          params.type = productType
          params.patient = flow?.user?._id
          
          // Only set hasKit if it evaluates to true
          if (hasKit && productType !== ProductTypeCode.TASSO) {
            params.hasKit = true
          } else {
            delete params.hasKit
          }

          // Only set hasAppointment if it evaluates to true
          if (hasAppointment && ![
            ProductTypeCode.MOBILE_BLOOD_DRAW, 
            ProductTypeCode.PRO_MOBILE_BLOOD_DRAW, 
            ProductTypeCode.LAB_VISIT].includes(productType)
          ) {
            params.hasAppointment = true
          } else {
            delete params.hasAppointment
          }

          response = await addProduct({
            fields: params,
          });

          if (currentUser?._id.toString() === response.patient?._id.toString()) {
            setCurrentUser(response.patient)
          }
        }
        
      }
      
      if (response.flow) {
        setFlow(response.flow)
      }
      
      if (cards && response.card && !cards.some(({ _id }) => _id === response.card._id)) {
        setCards([...cards, response.card])
      }

      if (newInstalabMembership) {
        setInstalabMembership(newInstalabMembership)
        setMemberships(cachedMemberships => {
          return [
            ...cachedMemberships,
            newInstalabMembership
          ]
        })
      }

      return response
    } catch (err) {
      console.log(err)
      const error = err?.response?.data?.err || 'Unable to charge card'
      message.error(error)
      throw new Error(error)
    } finally {
      setIsSubmittingPayment(false)
    }
  }

  const onDown = async () => {
    if (step.type === StepType.UPSELL) {
      // Exception: don't create Instalab subscription if this button is pressed
      await onNextStep()
    } else {
      onSubmit()
    }
  }

  return (step && predictedRoute?.length) ? <>
    <Affix offsetTop={0} className="flow-progress-affix">
      <Progress 
        percent={predictedRoute?.length ? (predictedRoute.findIndex(c => c === code))/(predictedRoute?.length - 1)*100 : 0} 
        showInfo={false}
        className="flow-progress"
        style={{ 
          top: width >= Breakpoint.LG ? 0 : 52,
          position: width >= Breakpoint.LG ? 'relative' : 'fixed',
        }}
        strokeColor={Color.success}
      />
    </Affix>
    
    <div className="flow">
      <FlowStep
        index={predictedRoute.findIndex(c => c === code) + startIndex}
        flow={flow}
        setFlow={setFlow}
        step={step}
        form={form}
        skeleton={skeleton}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        hasAttempt={hasAttempt}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
        cards={cards}
        setCards={setCards}
        activeCard={activeCard}
        setActiveCard={setActiveCard}
        productTypeCode={productTypeCode || step.productType}
      />

      {width > Breakpoint.SM && (
        <div className="flow-footer">
          <div className="flow-nav">
            <Button
              icon={<UpOutlined />}
              type='primary'
              className="flow-nav-up"
              onClick={onPrevStep}
              disabled={Object.keys(skeleton)[0] === code}
            />
            <Button
              icon={<DownOutlined />}
              type='primary'
              className="flow-nav-down"
              onClick={onDown}
              disabled={!step.nextStep && !step.onNextStep}
            />
          </div>

          <Button 
            className="flow-contact"
            type='primary'
            onClick={() => window.FrontChat("show")}
            icon={<MessageFilled />}
          >
            Contact Us
          </Button>
        </div>
      )}
    </div>
  </> : (
    <div className="loading-flow">
      <Spin size="small" /> <Text className="loading-flow-text">Loading...</Text>
    </div>
  )
}