import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useEffect, useRef, useContext } from "react"
import { useSearchParams } from "react-router-dom"
import MemberConfirmed from "./custom/memberConfirmed/memberConfirmed.component"
import MemberIntro from "./custom/memberIntro/memberIntro.component"
import { getMembershipTypeByCode, listMembershipTypes } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import MembershipHelper from "../../helpers/membership.helper"
import { UserContext } from "../../contexts/user.context"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  INTRO: 'intro',
  SELECT: 'select',
  SELECT_DURATION: 'select-duration',
  PAY: 'pay',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  PASSWORD: 'password',
  CONFIRM: 'confirm',
}

export const MemberFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [searchParams] = useSearchParams()


  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    const urlMembershipTypeCode = searchParams.get('code')    
    if (urlMembershipTypeCode && !flow?.membershipTypeCode && membershipTypes?.some(type => type.code === urlMembershipTypeCode)) {
      setFlow(prev => ({
        ...prev,
        membershipTypeCode: urlMembershipTypeCode
      }))
    }
  }, [flow, membershipTypes])


  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, flow, hasPass, initialUser, instalabMembership, currentUser])

  const getMembershipType = (code) => {
    if (!code) return null
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
            MembershipTypeCode.PREMIUM,
            MembershipTypeCode.LONGEVITY_12MO,
            MembershipTypeCode.PREMIUM_12MO
          ]
        }
      }
    }))
  }


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser  || !membershipTypes?.length) return

    const totalCost = flow?.membershipTypeCode ? ProductHelper.getTotalCost([getMembershipType(flow?.membershipTypeCode)], instalabMembership, currentUser) : 0

    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <MemberIntro footer={footer} initialMembershipType={getMembershipType(flow?.membershipTypeCode)} currentUser={currentUser}/>
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        onNextStep: () => {
          return flow?.membershipTypeCode ? Step.PAY : Step.SELECT
        },
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.MEMBER}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },
      [Step.SELECT]: {
        type: StepType.PRODUCT_SELECT,
        title: `Which Instalab membership fits your needs?`,
        description: <>Both memberships include access to your own physician, exclusive test pricing, specialist referrals, and concierge coordination.
        <p><strong>The only difference:</strong> Core is pay-per-visit physician consults, and Premium includes unlimited physician consults at no extra charge.</p>
        </>,
        nextStep: Step.SELECT_DURATION,
        buildFlow: true,
        field: {
          name: 'membershipTypeCode',
          required: true,
          options: [
            {
              title: getMembershipType(MembershipTypeCode.LONGEVITY_12MO)?.title,
              value: MembershipTypeCode.LONGEVITY_12MO,
              description: "Includes your own physician ($99/session).",
              price: ProductHelper.getTotalCost([getMembershipType(MembershipTypeCode.LONGEVITY_12MO)], instalabMembership, currentUser) === 0 ? 'Free' : `$${Math.round(getMembershipType(MembershipTypeCode.LONGEVITY_12MO)?.cost/12)}/month`
            },
            {
              title: getMembershipType(MembershipTypeCode.PREMIUM_12MO)?.title,
              value: MembershipTypeCode.PREMIUM_12MO,
              description: "Includes unlimited physician access ($0/session).",
              price: ProductHelper.getTotalCost([getMembershipType(MembershipTypeCode.PREMIUM_12MO)], instalabMembership, currentUser) === 0 ? 'Free' : `$${Math.round(getMembershipType(MembershipTypeCode.PREMIUM_12MO)?.cost/12)}/month`
            },
          ],
        },
      },
      [Step.SELECT_DURATION]: {
        type: StepType.PRODUCT_SELECT,
        title: `Choose your commitment level for ${getMembershipType(flow?.membershipTypeCode)?.title}.`,
        nextStep: Step.PAY,
        buildFlow: true,
        field: {
          name: 'membershipTypeCode',
          required: true,
          options: getDurationOptions([MembershipTypeCode.PREMIUM_12MO, MembershipTypeCode.PREMIUM].includes(flow?.membershipTypeCode))
        },
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addSubscription: true,
        paymentTypes: ['card'],
        subscriptionType: flow?.membershipTypeCode,
        title: () => { 
          const selectedMembershipType = getMembershipType(flow?.membershipTypeCode);
          if (totalCost === 0) {
            return <>Activate your <span>{selectedMembershipType?.title} {selectedMembershipType?.isAnnual ? 'for a year' : ''}</span> for <span className="true-price">${totalCost}</span>.</>
          }
          else if (selectedMembershipType) {
            const isAnnual = [MembershipTypeCode.PREMIUM_12MO, MembershipTypeCode.LONGEVITY_12MO].includes(selectedMembershipType.code);
            return <>Activate your <span>{selectedMembershipType?.title}</span> for <span className="true-price">${totalCost}{isAnnual ? '/year' : '/month'}</span>.</>
          }

          return 'Activate your membership'
        },
        description: () => {
          if (totalCost === 0) {
            return <>You’ll pay nothing for the first year. If plans change, cancellation is easy and always available before renewal.</>
          }

          const selectedMembershipType = getMembershipType(flow?.membershipTypeCode);
          const isAnnual = selectedMembershipType?.isAnnual
          
          return isAnnual ? <>You’ll be billed annually, but if plans change, cancellation is easy and always available before renewal.</> :
            <>You'll be billed monthly, but if it's not the right fit, cancellation is easy and always available before renewal.</> ;
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this to set up your account.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.CONFIRM]: {
        type: StepType.CUSTOM,
        content: ({ footer }) => <MemberConfirmed footer={footer} instalabMembership={flow?.membership} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  const getDurationOptions = (isPremium) => {
    const baseType = isPremium ? MembershipTypeCode.PREMIUM : MembershipTypeCode.LONGEVITY;
    const annualType = isPremium ? MembershipTypeCode.PREMIUM_12MO : MembershipTypeCode.LONGEVITY_12MO;
    return [
      {
        title: 'Monthly',
        value: baseType,
        description: "Ideal if you're just curious about the benefits and want to dabble. Billed monthly with the flexibility to cancel anytime.",
        price: ProductHelper.getTotalCost([getMembershipType(baseType)], instalabMembership, currentUser) === 0 ? 'Free' : `$${getMembershipType(baseType)?.cost}/month`
      },
      {
        title: 'Annual',
        value: annualType,
        description: `Perfect for those dedicated to long-term health. Enjoy 2 months free with an annual commitment of $${getMembershipType(annualType)?.cost}.`,
        price: ProductHelper.getTotalCost([getMembershipType(annualType)], instalabMembership, currentUser) === 0 ? 'Free' : `$${Math.round(getMembershipType(annualType)?.cost/12)}/month`
      },
    ];
  };

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}