import {
  CalendarOutlined,
  UserOutlined,
  DollarCircleOutlined,
  TableOutlined,
  FileSearchOutlined,
  ExperimentOutlined,
  ReadOutlined,
  LoginOutlined,
  GiftOutlined,
  LogoutOutlined,
  EditOutlined,
  ShopOutlined,
  ProfileOutlined,
  HomeOutlined,
  MessageOutlined,
  FileDoneOutlined,
  CheckSquareOutlined,  
  BankOutlined,
  SendOutlined,
  HeartOutlined,
  SettingOutlined,
  StarOutlined,
  VideoCameraOutlined,
  PlusCircleOutlined,
  FileOutlined,
  AreaChartOutlined
} from '@ant-design/icons'
import { ReactComponent as TubeOutlined } from '../assets/svg/blood-vial.svg'
import { ReactComponent as DoctorOutlined } from "../assets/svg/doctor.svg"
import FlowType from '../enums/flowType.enum'
import Role from '../enums/role.enum';
import Permission from '../enums/permission.enum';
import FrontChatHelper from './fronchat.helper';
import MembershipHelper from './membership.helper'
import { Tag } from 'antd';
const LayoutHelper = {

  getMenuItems: (counts, currentUser, membership, navigate, consults) => {
    if (!counts) return

    FrontChatHelper.identifyUser(currentUser);


    const testList = [
      // {
      //   key: 'truage',
      //   label: '- Biological Age Test',
      //   urls: ['/truage'],
      //   onClick: () => navigate(`/truage`)
      // },
      // {
      //   key: 'cgm',
      //   label: "- Continuous Glucose Monitor",
      //   urls: [`/flow/${FlowType.CGM}`],
      //   onClick: () => navigate(`/flow/${FlowType.CGM}`)
      // },
      {
        key: 'ccta',
        label: "- CT Coronary Angiogram",
        urls: [`/cleerly`],
        onClick: () => navigate(`/cleerly`)
      },
      {
        key: 'biobeat',
        label: "- Continuous BP Monitor",
        urls: [ `/biobeat`],
        onClick: () => navigate(`/biobeat`)
      },
      {
        key: 'dexa-bodycommp',
        label: "- DEXA Body Composition",
        urls: [`/flow/${FlowType.DEXA_BODYCOMP}`],
        onClick: () => navigate(`/flow/${FlowType.DEXA_BODYCOMP}`)
      },
      {
        key: 'dexa-bone',
        label: "- DEXA Bone Density",
        urls: [`/flow/${FlowType.DEXA}`],
        onClick: () => navigate(`/flow/${FlowType.DEXA}`)
      },
      {
        key: 'cortisol',
        label: "- Diurnal Cortisol Profile",
        urls: [`/flow/${FlowType.DIURNAL_CORTISOL_PROFILE}`],
        onClick: () => navigate(`/flow/${FlowType.DIURNAL_CORTISOL_PROFILE}`)
      },
      {
        key: 'fertility',
        label: "- Fertility Hormone Test",
        urls: [`/fertility`],
        onClick: () => navigate(`/fertility`)
      },
      {
        key: 'heart-health',
        label: "- Heart Health Test",
        urls: [ `/heart-health-test`],
        onClick: () => navigate(`/heart-health-test`)
      },
      {
        key: 'mold-toxins',
        label: "- Mold Toxins Test",
        urls: [ `/flow/${FlowType.MOLD_TOXINS}`],
        onClick: () => navigate(`/flow/${FlowType.MOLD_TOXINS}`)
      },
      {
        key: 'sleep',
        label: "- Sleep Apnea Test",
        urls: [`/flow/${FlowType.SLEEP_APNEA}`],
        onClick: () => navigate(`/flow/${FlowType.SLEEP_APNEA}`)
      },
      {
        key: 'testosterone',
        label: "- Testosterone Panel",
        urls: [ `/testosterone`],
        onClick: () => navigate(`/testosterone`)
      },
      {
        key: '5',
        label: "- Toxic Metals Test",
        urls: [ `/flow/${FlowType.TOXIC_METALS_TEST}`],
        onClick: () => navigate(`/flow/${FlowType.TOXIC_METALS_TEST}`)
      },
      {
        key: 'vo2max',
        label: "- Vo2 Max",
        urls: [ `/flow/${FlowType.VO2MAX}`],
        onClick: () => navigate(`/flow/${FlowType.VO2MAX}`)
      },
      {
        key: 'prenuvo',
        label: "- Whole Body MRI Scan",
        urls: [ `/flow/${FlowType.PRENUVO}`],
        onClick: () => navigate(`/flow/${FlowType.PRENUVO}`)
      },
    ]

    const popularTestList = [
      {
        key: 'longevity',
        label: "- Longevity Blood Panel",
        urls: [ "/longevity-panel"],
        onClick: () => navigate("/longevity-panel")
      },
      {
        key: 'cac',
        label: "- Coronary Calcium Scan",
        urls: [`/cac`],
        onClick: () => navigate(`/cac`)
      },
      {
        key: 'galleri',
        label: "- Galleri Cancer Detection Test",
        urls: [ `/galleri-test`],
        onClick: () => navigate(`/galleri-test`)
      },

    ]

    if (currentUser?.role === Role.ADMIN) {

      const apptItems = [
        {
          key: 'calendar',
          label: (
            <>
              - Master Calendar
              <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/calendar'],
          onClick:() => navigate('/admin/calendar')
        },
        {
          key: 'appointments',
          label: (
            <>
              - Appointments
              <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/appointments'],
          onClick:() => navigate('/admin/appointments')
        },       
        {
          key: 'Longevity-Consults',
          label: (
            <>
              - Consultations
              <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/consults'],
          onClick:() => navigate('/admin/consults')
        }, 
        {
          key: 'Lab Orders',
          label: (
            <>
              - Lab Orders
              <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
            </>
          ),
          // icon: <ProfileOutlined />,
          urls: ['/admin/orders'],
          onClick:() => navigate('/admin/orders')
        }, 
 
        {
          key: 'Events',
          label: (
            <>
              - Events
              <div className="menu-item-count">{counts.events?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/events'],
          onClick:() => navigate('/admin/events')
        }, 
      ]

      const userItems = [
        { 
          key: 'Patients', 
          urls: ['/', '/admin/patients'],
          onClick: () => navigate('/'),
          label: (
          <>
            - Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Members', 
          urls: ['/admin/members'],
          onClick: () => navigate('/admin/members'),
          label: (
          <>
            - Members
            <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
          </>
          )
        },
       
        { 
          key: 'Phlebotomists', 
          urls: ['/admin/phlebotomists'],
          onClick: () => navigate('/admin/phlebotomists'),
          label: (
          <>
            - Phlebotomists
            <div className="menu-item-count">{counts.phlebotomists?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Providers', 
          urls: ['/admin/providers'],
          onClick: () => navigate('/admin/providers'),
          label: (
          <>
            - Providers
            <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Staff', 
          urls: ['/admin/staffs'],
          onClick: () => navigate('/admin/staffs'),
          label: (
          <>
            - Staff
            <div className="menu-item-count">{counts.staff?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Admins', 
          urls: ['/admin/admins'],
          onClick: () => navigate('/admin/admins'),
          label: (
          <>
            - Admins
            <div className="menu-item-count">{counts.admins?.toLocaleString()}</div>
          </>
          )
        },
        {
          key: 'Facilities',
          label: (
            <>
              - Facilities
              <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/facilities'],
          onClick:() => navigate('/admin/facilities')
        }, 
      ]

      const resultItems = [
        {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
              <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/admin/results'],
          onClick:() => navigate('/admin/results')
        }, {
          key: 'reports',
          label: (
            <>
              - Reports
              <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileSearchOutlined />,
          urls: ['/admin/reports'],
          onClick:() => navigate('/admin/reports')
        }, 
        {
          key: 'Biomarkers',
          label: (
            <>
              - Biomarkers
              <div className="menu-item-count">{counts.tests?.toLocaleString()}</div>
            </>
          ),
          // icon: <ExperimentOutlined />,
          urls: ['/admin/tests'],
          onClick:() => navigate('/admin/tests')
        }, 
        {
          key: 'Files',
          label: (
            <>
              - File Archives
              <div className="menu-item-count">{counts.files?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileOutlined />,
          urls: ['/admin/files'],
          onClick:() => navigate('/admin/files')
        }
      ]

      const productItems = [
        {
          key: 'Product-Orders',
          label: (
            <>
              - All Products
              <div className="menu-item-count">{counts.products?.toLocaleString()}</div>
            </>
          ),
          // icon: <ShopOutlined />,
          urls: ['/admin/products'],
          onClick:() => navigate('/admin/products')
        },

        {
          key: 'Test-Kits',
          label: (
            <>
              - Test Kits
              <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
            </>
          ),
          // icon: <ShopOutlined />,
          urls: ['/admin/kits'],
          onClick:() => navigate('/admin/kits')
        },

        {
          key: 'Prescriptions',
          label: (
            <>
              - Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/admin/prescriptions'],
          onClick:() => navigate('/admin/prescriptions')
        }, 
        {
          key: 'Memberships',
          label: (
            <>
              - Memberships
              <div className="menu-item-count">{counts.memberships?.toLocaleString()}</div>
            </>
          ),
          // icon: <KeyOutlined />,
          urls: ['/admin/memberships'],
          onClick:() => navigate('/admin/memberships')
        }, 
        {
          key: 'Gifts',
          label: (
            <>
              - Gifts
              <div className="menu-item-count">{counts.gifts?.toLocaleString()}</div>
            </>
          ),
          // icon: <GiftOutlined />,
          urls: ['/admin/gifts'],
          onClick:() => navigate('/admin/gifts')
        },
        {
          key: 'Referrals',
          label: (
            <>
              - Referrals
              <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
            </>
          ),
          // icon: <SendOutlined />,
          urls: ['/admin/referrals'],
          onClick:() => navigate('/admin/referrals')
        },
  

        // { 
        //   key: 'Membership-Types', 
        //   urls: ['/admin/membership-types'],
        //   onClick: () => navigate('/admin/membership-types'),
        //   label: (
        //   <>
        //     - Membership Pricing
        //     <div className="menu-item-count">{counts.membershipTypes?.toLocaleString()}</div>
        //   </>
        //   )
        // },
        // { 
        //   key: 'Product-Types', 
        //   urls: ['/admin/product-types'],
        //   onClick: () => navigate('/admin/product-types'),
        //   label: (
        //   <>
        //     - Product Pricing
        //     <div className="menu-item-count">{counts.productTypes?.toLocaleString()}</div>
        //   </>
        //   )
        // }
      ]

      const starItems = [
        { 
          key: 'Patients', 
          urls: ['/', '/admin/patients'],
          onClick: () => navigate('/'),
          label: (
          <>
            - Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Members', 
          urls: ['/admin/members'],
          onClick: () => navigate('/admin/members'),
          label: (
          <>
            - Members
            <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Heart Health', 
          urls: ['/admin/hh'],
          onClick: () => window.open('/admin/hh', '_blank'),
          label: (
          <>
            - Heart Health
            <div className="menu-item-count">{counts.hhMembers?.toLocaleString()}</div>
          </>
          )
        },
        {
          key: 'calendar',
          label: (
            <>
              - Master Calendar
              <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/calendar'],
          onClick:() => navigate('/admin/calendar')
        },
        {
          key: 'Test Kits',
          label: (
            <>
              - Test Kits
              <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/kits'],
          onClick:() => navigate('/admin/kits')
        }, 
        {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
              <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/admin/results'],
          onClick:() => navigate('/admin/results')
        }, {
          key: 'reports',
          label: (
            <>
              - Reports
              <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileSearchOutlined />,
          urls: ['/admin/reports'],
          onClick:() => navigate('/admin/reports')
        }, 
      ]

      return [
      {
        key: 'Starred',
        label: 'Starred',
        icon: <StarOutlined />,
        children: starItems
      }, 
  
      {
        key: 'Users',
        label: 'Users',
        icon: <UserOutlined />,
        children: userItems
      }, 
      {
        key: 'Appointments',
        label: 'Appointments',
        icon: <CalendarOutlined />,
        children: apptItems
      },
      {
        key: 'Results',
        label: 'Results',
        icon:  <ExperimentOutlined />,
        children: resultItems
      },
      {
        key: 'Products',
        label: 'Orders',
        icon: <ShopOutlined />,
        children: productItems
      },
      {
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/admin/tasks'],
        onClick:() => navigate('/admin/tasks')
      },

      {
        key: 'Billing',
        label: (
          <>
            Billing
            <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
          </>
        ),
        icon: <DollarCircleOutlined />,
        urls: ['/billing'],
        onClick:() => navigate('/billing')
      }, 
      // {
      //   key: 'Logout',
      //   label: 'Logout',
      //   icon: <LogoutOutlined />,
      //   urls: ['/logout'],
      //   onClick:() => navigate('/logout')
      // }
      ]
    } 
    else if (currentUser?.role === Role.PROVIDER) {
      let providerItems = [
        
      {
        key: 'Patients',
        label: (
          <>
            Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
        </>
        ),
        icon: <UserOutlined />,
        urls: ['/provider/patients'],
        onClick:() => navigate('/provider/patients')
      }, 
      {
        key: 'Blood-Draws',
        label: (
          <>
            Appointments
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        icon: <CalendarOutlined />,
        urls: ['/provider/appointments'],
        onClick:() => navigate('/provider/appointments')
      },       {
        key: 'Consults',
        label: (
          <>
            Consults
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        icon: <VideoCameraOutlined />,
        urls: ['/provider/consults'],
        onClick:() => navigate('/provider/consults')
      }, 
      {
        key: 'Kits',
        label: (
          <>
            Test Kits
            <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
          </>
        ),
        icon: <TubeOutlined />,
        urls: ['/provider/kits'],
        onClick:() => navigate('/provider/kits')
      }, 

      {
        key: 'Test-Results',
        label: (
          <>
            Test Results
            <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
          </>
        ),
        icon: <TableOutlined />,
        urls: ['/provider/results'],
        onClick:() => navigate('/provider/results')
      }, 
      {
        key: 'reports',
        label: (
          <>
            Reports
            <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
          </>
        ),
        icon: <FileSearchOutlined />,
        urls: ['/provider/reports'],
        onClick:() => navigate('/provider/reports')
      },
      // {
      //   key: 'referrals',
      //   label: (
      //     <>
      //       Referrals 
      //       <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
      //     </>
      //   ),
      //   icon: <SendOutlined />,
      //   urls: ['/provider/referrals'],
      //   onClick:() => navigate('/provider/referrals')
      // }
      ]

      if (currentUser?.chargePersonalCard) {
        providerItems.push({
          key: 'Billing',
          label: (
            <>
              Billing
              <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
            </>
          ),
          icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        })
      }

      if (currentUser?.permissions?.includes(Permission.PRESCRIPTIONS)) {
        providerItems.push({
          key: 'Prescriptions',
          label: (
            <>
              Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          icon: <EditOutlined />,
          urls: ['/provider/prescriptions'],
          onClick:() => navigate('/provider/prescriptions')
        })
      }
    
      providerItems = [        
      {
        key: 'Test-Catalog',
        label: (
          <>
            Order Test
          </>
        ),
        icon: <ShopOutlined/>,
        urls: ["/", '/provider/catalog'],
        onClick:() => navigate('/provider/catalog')
      },  
      // {
      //   key: 'Availability',
      //   label: (
      //     <>
      //       Check Availability
      //     </>
      //   ),
      //   icon: <SearchOutlined />,
      //   urls: [`/pro-flow/${FlowType.PRO_AVAILABILITY}`],
      //   onClick:() => navigate(`/pro-flow/${FlowType.PRO_AVAILABILITY}`)
      // },  

      {
        type: 'divider'
      },

        ...providerItems,
        {
          type: 'divider'
        },
        {
          key: 'Contact',
          label: (
            <>
              Concierge
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => {
            window.FrontChat("show")
          }
        }, 
        {
          key: 'Logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
          urls: ['/logout'],
          onClick:() => navigate('/logout')
        }
      ]

      return providerItems
    } 
    else if (currentUser?.role === Role.PHLEBOTOMIST) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/phlebotomist/tasks'],
        onClick:() => navigate('/phlebotomist/tasks')
      }]
    }
    else if (currentUser?.role === Role.STAFF) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/staff/tasks'],
        onClick:() => navigate('/staff/tasks')
      }, 
      { 
        key: 'Heart Health',
        label: (
          <>
            Heart Health
          </>
        ),
        icon: <HeartOutlined />,
        urls: ['/staff/hh'],
        onClick:() => window.open('/staff/hh', '_blank')
      },

      {
        key: 'Lab Orders',
        label: (
          <>
            Lab Orders
            {/* <div className="menu-item-count">{counts.orders?.toLocaleString()}</div> */}
          </>
        ),
        icon: <ProfileOutlined />,
        urls: ['/staff/orders'],
        onClick:() => navigate('/staff/orders')
      },
      { 
        key: 'Appointments', 
        urls: ['/staff/appointments'],
        onClick: () => navigate('/staff/appointments'),
        icon: <CalendarOutlined/> ,
        label: (
        <>
          Appointments
        </>
        )
      }, 
      { 
        key: 'Phlebotomists', 
        urls: ['/staff/phlebotomists'],
        onClick: () => navigate('/staff/phlebotomists'),
        icon: <UserOutlined/> ,
        label: (
        <>
          Phlebotomists
        </>
        )
      },
      { 
        key: 'Providers', 
        urls: ['/staff/providers'],
        onClick: () => navigate('/staff/providers'),
        icon: <DoctorOutlined/>,
        label: (
        <>
          Providers
          <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
        </>
        )
      },
      {
        key: 'Facilities',
        icon: <BankOutlined/>,
        label: (
          <>
            Facilities
            <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
          </>
        ),
        urls: ['/staff/facilities'],
        onClick:() => navigate('/staff/facilities')
      }, 
      {
        key: 'Referrals',
        label: (
          <>
            Referrals
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/staff/referrals'],
        onClick:() => navigate('/staff/referrals')
      },
      {
        type: 'divider'
      }, {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]
    }
    else if (currentUser?.role === Role.PATIENT && currentUser?.email) {

      let careItems = [
        {
          key: 'order-test',
          label: <>- Order Test</>,
          urls: ["/catalog"],
          onClick:() => navigate('/catalog'),
        },
        {
          key: 'book-consult',
          label: <>- Longevity Consult</>,
          urls: [`/flow/${FlowType.LONGEVITY_CONSULT}`],
          onClick:() => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`),
        },
        MembershipHelper.getActiveHHMembership(currentUser?.memberships) && {
          key: 'book-hh-consult',
          label: <>- Heart Health Consult</>,
          urls: [`/flow/${FlowType.HEART_MEMBER_CONSULT}`],
          onClick:() => navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`),
        },
        {
          key: 'request-referral',
          label: <>- Specialist Referral</>,
          urls: [`/flow/${FlowType.REFERRAL}`],
          onClick:() => navigate(`/flow/${FlowType.REFERRAL}`),
        },
        {
          key: 'message-concierge',
          label: <>- Care Concierge</>,
          onClick:() => window.FrontChat("show")
        },
        (!membership || !MembershipHelper.isActive(membership)) && {
          key: 'join-as-member',
          label: <>- Join as Member</>,
          // urls: ['/patient/memberships'],
          onClick:() => navigate('/patient/memberships'),

        }

      ];


      let orderItems = [
        {
          key: 'blood-draws',
          label: "- Appointments",
          // icon: <CalendarOutlined/>,
          urls: [ "/patient/appointments"],
          onClick: () => navigate("/patient/appointments")
        },
        {
          key: 'consultations',
          label: '- Consults',
          urls: ["/patient/consults"],
          // icon: <DoctorOutlined/>,
          onClick: () => navigate("/patient/consults"),
        },
        {
          key: 'test-kits',
          label: "- Test Kits",
          urls: [ "/patient/kits"],
          // icon: <TubeOutlined/>,
          onClick: () => navigate("/patient/kits")
        },

        {
          key: 'Treatments',
          label: (
            <>
              - Prescriptions
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/patient/treatments'],
          onClick:() => navigate('/patient/treatments')
        },
      ];

      let accountItems = [
        {
          key: 'Profile',
          label: (
            <>
              - Profile
            </>
          ),
          // icon: <IdcardOutlined />,
          urls: ['/patient/profile'],
          onClick:() => navigate('/patient/profile')
        },
        {
          key: 'Membership',
          label: (
            <>
              - Membership
            </>
          ),
          // icon: <IdcardOutlined />,
          urls: ['/patient/memberships'],
          onClick:() => navigate('/patient/memberships')
        },
        {
          key: 'Billing',
          label: (
            <>
              - Payments
            </>
          ),
          // icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        }
      ];
    
      let myHealthItems=[];

      if (counts.results?.toLocaleString() > 0) {
          myHealthItems = [...myHealthItems, {
          key: 'Test-Results',
          label: (
            <>
              Test Results
            </>
          ),
          icon: <TableOutlined />,
          urls: ['/patient/results'],
          onClick:() => navigate('/patient/results')
        }]
      }

      if (counts?.reports>0) {
        myHealthItems = [...myHealthItems, 
          {
            key: 'Latest-Report',
            label: 'Latest Report',
            icon: <FileSearchOutlined />,
            urls: ['/patient/report'],
            onClick:() => navigate('/patient/report')
          },
          {
            key: 'Game-Plan',
            label: 'Game Plan',
            icon: <CheckSquareOutlined />,
            urls: ['/patient/plan'],
            onClick:() => navigate('/patient/plan')
          },
        ]
      }

      // Release to HH patients one at at time
      if (
        [
          // Heart Health Members (28 patients):
          '658d31ad6bc05597140c82dc',  // Scott Armstrong
          // '65dd362cf37e3935a7e7aeaa',  // Zoe Warczak
          '659c216a6ea0ff389eaaabe1',  // Mark Daniel
          // '65e5f02ff6e0254d15ca16c7',  // Diedrik Vermeulen
          '65e6018df6e0254d15cab9c1',  // Robert Harrell
          // '65f0ddb6809827f524c81ce3',  // Nicholas Mandal
          // '652b4d9ae5c54ce7d7e139e5',  // Kovas Boguta
          // '6539cf0917896120c29bfeb5',  // Bo Lu
          // '65ee07c1499625243e9dbc34',  // Richard White
          // '6623dcf510454fb81d655ff4',  // Ken Falk
          // '662937454fb23fe22d333eb9',  // Runde Lu
          // '66479691fc57f74c34047e23',  // Corey Hanrahan
          '665cb4f2bf58d7a8749c2865',  // Mukundhan Srinivasan
          // '660b340f27bffed2be050c1e',  // Brian Veverka
          // '65492cc5d2d989d3ff71f031',  // Daniel Benamy
          // '663e3278230090bece093859',  // Nathaniel Beachum
          // '665d1e427b80ca5889036463',  // Anton Makiievskyi
          // '6566455b9e7220f7026e66f3',  // Immad Akhund
          // '66d669df1e5da59878eadc86',  // William White
          '63d4f9dd8653989cbdb5702d',  // Alex Cheung
          '63d4f9de8653989cbdb57910',  // Jason Goldman
          '664bcb7c30e6856d843745b8',  // Maxwell Lu
          '63d4f9dd8653989cbdb57021',  // Adora Cheung
          // '671514cc1db08383f8b26a03',  // Gary Hull
          // '6722e0427c8d58630d1c7c65',  // Maxwell Bernardy
          '6713c9f91db08383f89a0311',  // Rodrigo Navarro Paolucci
          // '66afd7fcb53a962c70d7855c',  // Bruce Schneier
          // '67285c4e7c8d58630d82b143',  // Everest Munro-Zeisberger
          
          // Ultimate Members (1 patient):
          // '63d4f9dd8653989cbdb5710e',  // Aaron Cheung
          
      
          // Premium Members (1 patient):
          // '64bde39f532e29b668bcc790',  // Maggie Katz

          // Longevity Members (46 patients):
          // '63d763733df9ddfc29a7a14c',  // Kat Manalac
          // '63f7a2f4f757a4f5045749ca',  // Jeff Tang
          // '6582f132d96249d1f7a15ed0',  // Leo Chen
          // '659c216a6ea0ff389eaaabe1',  // Mark Daniel
          // '64fe52b613fdc713a659627f',  // K. Nathaniel Tucker
          // '65c0f7acf5a6204ff9c923cc',  // Jinal Jhaveri
          // '65c4f07ef33402224a377b07',  // Steven Winiarski
          // '65ca98f5a44d0d375048f067',  // Fatema Khatun
          // '63f14180068540539f9e848b',  // Layla Velazquez
          // '63d851bd6f6e33540278bdf8',  // Kenneth Donaghe
          // '65cc13c93a6c2d3bd0d5c11a',  // Yvonne Lin
          '64a32b1329be045f85d34abd', // Rishi Kacker
          // '63d4f9e08653989cbdb58557',  // Euwyn Poon
          // '65df94242fcdd3892d71e179',  // Jake Fuentes
          // '64f816c68db22a53a1ad97c0',  // Stephen Zerfas
          // '65ed28dc499625243e9b0c5d',  // Rusty Ralston
          // '645efbd8c5b1e5a4919d4841',  // Jyri Engestrom
          // '65f744056fd70f034430d4f7',  // Amer Hammour
          // '63df3a0ed104bdd7db602d9c',  // Amir Nathoo
          // '65d116968df01bc089995d57',  // Kunj Patel MD
          // '64b40f01917da1a2558e9673',  // Mikhail Esipov
          // '66297b394fb23fe22d3e7311',  // Wei Deng
          // '65f9e348c19929a6abfd8751',  // Yash Kothari
          // '64b929baee51ba881e07c57a',  // Nikolai Davydov
          // '664ba7153b7d183230daaa0c',  // Yan Hordiienko
          // '664ce3a891aef907290c5546',  // Lisa Li
          // '664fd1d3672a99e641dc5aeb',  // Pascale Diaine
          // '65dd362cf37e3935a7e7aeaa',  // Zoe Warczak
          // '663551d834885bda3cd6aa6b',  // Gregory Rosenthal
          // '6404eec331bf89638d78c112',  // Josh Dorward
          // '666775f002d75ec5785e6b77',  // Boqiu Guo
          // '666ddff01c21a609cd07fe2d',  // Gregory Levine
          // '6517183f5a7da0e6444104be',  // Megan Dombrowski
          // '668c1eb5d687e9e3c95db44c',  // Bradley Vaccaro
          // '66a728c9b53a962c703b8e8c',  // Xiaotong Zhou
          // '645a7a3611ce791a064442d1',  // Eliam Medina
          // '65e9653e46cb72a569339a41',  // Caleb Lane
          // '668e1bd50d43d4f0ce74876a',  // Svetlana Grinchenko
          // '67003694c408e0942d901849',  // William McEvoy
          // '6627cde5ee1b48381853acc8',  // Suhail Doshi
          // '65ce4fd053469d30468bd644',  // Aleem Mawani
          // '63ee2b312935caebe41a83fb',  // Michael Slavens
          // '656216e8c424f0df36ab53e1',  // Jonathan Parisort
          // '671522301db08383f8b35b9b',  // Jesus Salgueiro
          // '654a902b4b66cdb5a04439e1',  // Alex Beller
          // '6595e549eceae73773509330',  // Dustin Bouch
          // '66e9f8eb81e1a17b4f6cf5d0',  // Stephanie Simon

          // Misc (1 patient):
          '63d84f3e6f6e33540278b6f7', // Melody Tay
          '645a5e566c8a3e115e9a68e0', // Gustaf Alstromer


        ].includes(currentUser?._id)
      ) {
        myHealthItems.push({ 
          key: 'Exposure', 
          urls: ['/exposure'],
          onClick: () => window.open('/exposure', '_blank'),
          icon: <AreaChartOutlined />,
          label: (
          <>
            ApoB Exposure <Tag color="red" style={{ fontSize: '10px', marginLeft: '3px' }}>New</Tag>
          </>
          )
        },)
      }

      myHealthItems.push(
        {
        key: 'Medical-Information',
        label: 'Health Profile',
        icon: <UserOutlined/>,
        urls: ['/patient/medical'],
        onClick:() => navigate('/patient/medical')
      },
      {
        key: 'Files',
        label: (
          <>
            Health Files
          </>
        ),
        icon: <FileOutlined />,
        urls: ['/patient/files'],
        onClick:() => navigate('/patient/files')
      })

      // sidebar layout
      let items = [
        
      {
        key: 'Home',
        label: <>Home</>,
        icon: <HomeOutlined/>,
        urls: ['/', "/patient/home"],
        onClick:() => navigate('/patient/home'),
      },
      {
        key: 'order-test-sidebar',
        label: 'Order Test',
        icon: <ShopOutlined/>,
        urls: ["/catalog"],
        onClick:() => navigate('/catalog'),
      },
      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },
      {
        type: 'divider'
      },
      ...myHealthItems,
      {
        type: 'divider'
      },
      {
        key: 'orders',
        label: 'My Orders',
        icon: <ProfileOutlined />,
        children: [...orderItems]
      },
      {
        key: 'get-care',
        label: 'Get Care',
        icon: <PlusCircleOutlined/>,
        children: [...careItems]
      },

        {
          key: 'account',
          label: 'Account',
          icon: <SettingOutlined/>,
          children: accountItems
        },
        {
          key: 'Contact',
          label: (
            <>
             Help
            </>
          ),
          icon:  <MessageOutlined />,
          onClick: () => navigate('/help')
          // onClick:() => window.FrontChat("show")
        },         


      {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]

      return items
    } 
    else {
      return [{ 
        key: 'Home',
        label: 'Home',
        icon: <HomeOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, 

      {
        key: 'popular-tests',
        label: 'Test Catalog',
        icon: <TubeOutlined/>,
        children:[...popularTestList, ...testList]
      }, 
      {
        key: 'physician',
        label: 'Talk to Physician',
        icon: <DoctorOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
      },  

      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },


      {
        type: 'divider'
      }, 
      {
        key: 'Login',
        label: 'Login',
        icon: <LoginOutlined />,
        urls: ['/login'],
        onClick:() => navigate('/login')
      }, {
        type: 'divider'
      }, 
      { 
        key: 'Contact',
        label: 'Help',
        icon: <MessageOutlined />,
        onClick:() => window.FrontChat("show")
      }, 

            {
        key: 'Research',
        label: 'Research',
        icon: <ReadOutlined />,
        urls: ['/research'],
        onClick:() => window.open('/research', '_blank')
      }, {
        key: 'Terms',
        label: 'Terms',
        icon: <FileDoneOutlined />,
        urls: ['/terms'],
        onClick:() => navigate('/terms')
      }, 
      // {
      //   key: 'Privacy',
      //   label: 'Privacy',
      //   icon: <FileDoneOutlined />,
      //   urls: ['/privacy'],
      //   onClick:() => navigate('/privacy')
      // }
      ]
    }
  }
}

export default LayoutHelper
