import './launchTotalExposure.scss';
import { useContext, useEffect, useState } from 'react';
import { getTest, listTests } from '../../services/test.service';
import { addSelfReportedResults } from '../../services/result.service';
import TestCode from '../../enums/testCode.enum';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Color from '../../colors.scss';
import { Typography } from 'antd';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  TextField,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { UserContext } from '../../contexts/user.context';
import { RightOutlined } from '@ant-design/icons';

const { Paragraph } = Typography

const isValidDate = (date) => {
  if (!dayjs(date).isValid() || dayjs(date).format('MM/DD/YYYY') !== dayjs(date).format('MM/DD/YYYY')) {
    return false;
  }
  
  const currentYear = dayjs().year();
  const yearToCheck = dayjs(date).year();
  return yearToCheck >= (currentYear - 120) && yearToCheck <= currentYear;
};

export const LaunchTotalExposure = () => {
  const { currentUser } = useContext(UserContext)
  const [dataSource, setDataSource] = useState([{
    key: 1,
    testDate: null,
    apobValue: null,
  }]);
  const [tests, setTests] = useState([]);
  const [test, setTest] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchTests()
  }, [])

  useEffect(() => {
    fetchTest()
  }, [tests])

  const fetchTest = () => {
    setTest(tests.find(({ code }) => code === TestCode.APO_B))
  }

  const fetchTests = async () => {
    const tests = await listTests()
    setTests(tests)
  }

  const manageEmptyRowsHelper = (currentDataSource) => {
    const emptyRows = currentDataSource.filter(row => 
      !row.testDate && (!row.apobValue || row.apobValue === '')
    );

    let newDataSource = [...currentDataSource];

    if (emptyRows.length === 0) {
      newDataSource.push({
        key: currentDataSource.length + 1,
        testDate: null,
        apobValue: null,
      });
    }
    else if (emptyRows.length > 1) {
      const sortedEmptyKeys = emptyRows
        .map(row => row.key)
        .sort((a, b) => b - a);
      
      sortedEmptyKeys.slice(1).forEach(key => {
        if (!currentDataSource.find(row => row.key === key).testDate && 
            !currentDataSource.find(row => row.key === key).apobValue) {
          newDataSource = newDataSource.filter(row => row.key !== key);
        }
      });
    }

    return newDataSource;
  };

  const handleDateChange = (key, date) => {
    setDataSource(prevDataSource => {
      const newDataSource = prevDataSource.map((item) =>
        item.key === key ? { ...item, testDate: dayjs(date) } : item
      );
      return manageEmptyRowsHelper(newDataSource);
    });
  };

  const handleValueChange = (key, value) => {
    setDataSource(prevDataSource => {
      const newDataSource = prevDataSource.map((item) =>
        item.key === key ? { ...item, apobValue: value } : item
      );
      return manageEmptyRowsHelper(newDataSource);
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await addSelfReportedResults({
        fields: dataSource.map(({ testDate, apobValue }) => ({
          collectedAt: dayjs(testDate).toISOString(),
          values: [{
            test: test._id,
            value: apobValue,
          }]
        })),
      });
      console.log('Response:', response);
    } catch (error) {
      console.error('Error submitting results:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const handleDelete = (key) => {
    setDataSource(prevDataSource => {
      const newDataSource = prevDataSource.filter(item => item.key !== key);
      return manageEmptyRowsHelper(newDataSource);
    });
  };

  const isValidEntry = (row) => {
    return row.testDate && 
           isValidDate(row.testDate) && 
           row.apobValue && 
           Number(row.apobValue) >= 0;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="launch-total-exposure">
        <Paragraph className="launch-title">Tracking Lifetime ApoB Exposure</Paragraph>

        <Paragraph className="launch-description">
          Your cardiovascular health isn't just about a single ApoB reading—it's about the total amount of ApoB your arteries have endured over your lifetime. This is the key to understanding what truly drives plaque buildup and cardiovascular disease.
        </Paragraph>

        <Paragraph className="launch-description">
          Getting started with this tool is simple: just upload your ApoB readings below. It will estimate your total exposure to ApoB so far and predict when you might start facing serious cardiovascular risks like heart attacks in the future.
        </Paragraph>

        <Paragraph className="launch-description">
          If you have any questions, <a className="chat-link" onClick={onChat}>send us a message</a> and we're happy to help!
        </Paragraph>

        <Stack spacing={2}>
          <TableContainer component={Paper} sx={{ 
            '& .MuiTableCell-root': {
              borderBottom: '1px solid #f0f0f0'
            },
            '& .MuiTableHead-root': {
              backgroundColor: '#fafafa'
            }
          }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Test Date</TableCell>
                  <TableCell>ApoB Value (mg/dL)</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((record) => (
                  <TableRow key={record.key} 
                    sx={{ '&:hover': { backgroundColor: '#fafafa' } }}
                  >
                    <TableCell>
                      <DatePicker
                        value={record.testDate}
                        onChange={(date) => handleDateChange(record.key, date)}
                        format="MM/DD/YYYY"
                        slotProps={{ 
                          textField: { 
                            fullWidth: true,
                            size: "small",
                            sx: {
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                fontSize: '14px',
                                '& fieldset': {
                                  borderColor: '#d9d9d9'
                                },
                                '&:hover fieldset': {
                                  borderColor: '#bfbfbf'
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: Color.success
                                }
                              }
                            }
                          } 
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: "0" }}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        size="small"
                        placeholder="80"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            fontSize: '14px',
                            '& fieldset': {
                              borderColor: '#d9d9d9'
                            },
                            '&:hover fieldset': {
                              borderColor: '#bfbfbf'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: Color.success
                            }
                          }
                        }}
                        value={record.apobValue || ''}
                        onChange={(e) => handleValueChange(record.key, e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      {dataSource.length > 1 && (!(!record.testDate && !record.apobValue)) && (
                        <Button
                          danger
                          icon={<DeleteIcon style={{ fontSize: 18 }} />}
                          onClick={() => handleDelete(record.key)}
                          style={{ height: 38, width: 38 }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2}>
            <Button
              type={dataSource.some(row => isValidEntry(row)) ? "primary" : "default"}
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!dataSource.some(row => isValidEntry(row))}
              size="large"
              style={{
                height: 45,
                maxWidth: 300,
                width: '100%',
                borderRadius: 6,
                fontSize: 16,
                fontWeight: 500,
                marginTop: 20
              }}
            >
              {isSubmitting ? 'Launching...' : 'Launch Risk Calculator'}
              {!isSubmitting && <RightOutlined />}
            </Button>
          </Stack>
        </Stack>
      </div>
    </LocalizationProvider>
  );
};