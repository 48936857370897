import React, { useContext, useEffect, useState, useMemo } from "react";
import { Row, Col, Typography, Dropdown, Space, Button, Select, message } from "antd";
import Color from '../../colors.scss';
import Org from '../../enums/org.enum.js';
import { DownOutlined, RightOutlined, IdcardOutlined, HeartOutlined, HomeOutlined, ExperimentOutlined, BarChartOutlined, FileSearchOutlined, TeamOutlined, MedicineBoxOutlined, FileTextOutlined, UserSwitchOutlined } from "@ant-design/icons";
import logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import { addImpersonation } from '../../services/impersonation.service';
import copy from 'copy-to-clipboard';
import "./header.scss";
import MobileHeaderLayout from "../layouts/mobileHeader.layout.js";
import FlowType from "../../enums/flowType.enum.js";
import Breakpoint from '../../enums/breakpoint.enum'
import Role from "../../enums/role.enum.js";
import { getPatientDataCounts, listPatients } from "../../services/patient.service";
import useWidth from "../../hooks/useWidth.hook.js";
import { UserContext } from "../../contexts/user.context.js"; 
import { AnnouncementBanner } from "../announcementBanner/announcementBanner.component";
import ProductTypeCode from "../../enums/productTypeCode.enum";
import { listProductTypes } from "../../services/productType.service";
const { Text } = Typography


// Create a new memoized component for the search
const PatientSearch = React.memo(({ patients, navigate, currentUser }) => {
  const [searchText, setSearchText] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const searchResults = useMemo(() => {
    if (!searchText) return [];
    return patients.filter(patient =>
      `${patient.firstName}${patient.lastName}${patient.email}`
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(
          searchText.toLowerCase().replace(/\s+/g, '')
        )
    );
  }, [searchText, patients]);

  const patientOptions = useMemo(() => 
    patients.map(patient => ({
      label: (
        <div className="patient-option">
          <div className="patient-name">{patient.firstName} {patient.lastName}</div>
          <div className="patient-email">{patient.email}</div>
        </div>
      ),
      value: patient._id,
      patient: patient
    })), 
  [patients]);

  // Add state for counts
  const [patientCounts, setPatientCounts] = useState(null);

  // Add effect to fetch counts when search results change
  useEffect(() => {
    if (searchResults[0]?._id) {
      getPatientDataCounts(searchResults[0]._id)
        .then(counts => setPatientCounts(counts))
        .catch(console.error);
    } else {
      setPatientCounts(null);
    }
  }, [searchResults]);

  return (
    <Select
      showSearch
      placeholder="Search patients..."
      className="patient-search-bar"
      value={searchText} 
      options={patientOptions}
      open={dropdownOpen}
      onDropdownVisibleChange={(visible) => {
        if (visible !== dropdownOpen) {
          setDropdownOpen(visible);
        }
      }}
      onBlur={(e) => {
        if (!e.relatedTarget) {
          setDropdownOpen(false);
          if (!searchResults.length) {
            setSearchText(null);
          }
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && searchResults.length > 0) {
          e.preventDefault();
          const firstPatient = searchResults[0];
          setSearchText(`${firstPatient.firstName} ${firstPatient.lastName}`);
          navigate(`/patients/${firstPatient._id}`);
          setDropdownOpen(false);
        } else if (e.key === 'Escape') {
          setDropdownOpen(false);
          setSearchText(null);
          e.preventDefault();
        }
      }}
      onSearch={(value) => {
        setSearchText(value === '' ? null : value);
        setDropdownOpen(!!value);
      }}
      onClick={() => setDropdownOpen(true)}
      filterOption={(input, option) => {
        const patient = patients.find(p => p._id === option.value);
        if (!patient || !patient.firstName || !patient.lastName || !patient.email) return false;
        
        const fullName = `${patient.firstName}${patient.lastName}`
          .toLowerCase()
          .replace(/[^a-z]/g, ''); // Only keep letters
        const email = patient.email
          .toLowerCase()
          .replace(/[^a-z]/g, ''); // Only keep letters
        const searchTerm = input
          .toLowerCase()
          .replace(/[^a-z]/g, ''); // Only keep letters
        
        return fullName.includes(searchTerm) || email.includes(searchTerm);
      }}
      onSelect={(value) => {
        setDropdownOpen(false);
        navigate(`/patients/${value}`);
      }}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {searchResults[0] && (
            <div className="quick-actions">
              <div className="quick-actions-header">
                GO TO
              </div>
              <div className="quick-actions-list">
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<IdcardOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Profile`)}
                >
                  Patient Profile
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">Demographics & Contact</span>
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<HeartOutlined />}
                  onClick={() => navigate(`/exposure?patientId=${searchResults[0]._id}`)}
                >
                  Cumulative Exposure
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">Risk Analysis</span>
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<HomeOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Appointments`)}
                >
                  Blood Draws
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Home Visits
                  </span>
                  {patientCounts?.appointments > 0 && (
                    <span className="quick-action-count">{patientCounts.appointments}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<FileTextOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Notes`)}
                >
                  Clinical Notes
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Medical History
                  </span>
                  {patientCounts?.notes > 0 && (
                    <span className="quick-action-count">{patientCounts.notes}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<ExperimentOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Kits`)}
                >
                  Test Kits
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Self Collection
                  </span>
                  {patientCounts?.testKits > 0 && (
                    <span className="quick-action-count">{patientCounts.testKits}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<BarChartOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Results`)}
                >
                  Test Results
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Biomarker Values
                  </span>
                  {patientCounts?.results > 0 && (
                    <span className="quick-action-count">{patientCounts.results}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<FileSearchOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Reports`)}
                >
                  Reports
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Clinical Analysis
                  </span>
                  {patientCounts?.reports > 0 && (
                    <span className="quick-action-count">{patientCounts.reports}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<TeamOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Consults`)}
                >
                  Consultations
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Specialist Meetings
                  </span>
                  {patientCounts?.consultations > 0 && (
                    <span className="quick-action-count">{patientCounts.consultations}</span>
                  )}
                </Button>
                <Button 
                  className="quick-action-btn"
                  type="text" 
                  icon={<MedicineBoxOutlined />}
                  onClick={() => navigate(`/patients/${searchResults[0]._id}?key=Prescriptions`)}
                >
                  Prescriptions
                  <RightOutlined className="path-separator" />
                  <span className="path-detail">
                    Medications
                  </span>
                  {patientCounts?.prescriptions > 0 && (
                    <span className="quick-action-count">{patientCounts.prescriptions}</span>
                  )}
                </Button>
                {currentUser?.role === Role.ADMIN && (
                  <Button 
                    className="quick-action-btn"
                    type="text" 
                    icon={<UserSwitchOutlined />}
                    onClick={async () => {
                      try {
                        const impersonate = await addImpersonation({ user: searchResults[0]._id });
                        copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`);
                        message.success('Copied login link');
                      } catch (err) {
                        message.error('Failed to generate login link');
                      }
                    }}
                  >
                    Impersonate
                    <RightOutlined className="path-separator" />
                    <span className="path-detail">
                      Log in as Patient
                    </span>
                  </Button>
                )}
              </div>
              <div className="search-results-footer">
                <div className="search-results-info">
                  <span className="search-results-label">Showing results for:</span>
                  <span className="search-results-name">
                    {searchResults[0].firstName} {searchResults[0].lastName}
                  </span>
                  <span className="search-results-email">
                    ({searchResults[0].email})
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
});

export const Header = ({ isPro=false, hasMobile=true, hasSearch=true }) => {
  const navigate = useNavigate();
  const [headerItems, setHeaderItems] = useState([]);

  const { token, currentUser, instalabMembership } = useContext(UserContext)
  const width = useWidth()
  const [patients, setPatients] = useState([]);
  const isTeammate = currentUser && currentUser?.role !== Role.PATIENT

  const [productTypes, setProductTypes] = useState(null)

  useEffect(() => {
    const fetchProductTypes = async () => {
      setProductTypes(await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.LONGEVITY_PANEL,
            ]
          }
        }
      }))
    }
    fetchProductTypes()
  }, [])

  const getProductType  = (productTypeCode) => {
    return productTypes?.find(productType => productType.code === productTypeCode)
  }

  useEffect(() => {
    const loadPatients = async () => {
      if (isTeammate) {
        const allPatients = await listPatients({
          select: 'dob _id firstName lastName email',
          filter: {
            org: Org.INSTALAB,
            firstName: {
              $exists: true
            },
            lastName: {
              $exists: true
            },
            // email: {
            //   $exists: true
            // }
          }
        });
        setPatients(allPatients.sort((a, b) => {
          // First compare by firstName
          const firstNameCompare = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
          // If firstNames are equal, compare by lastName
          if (firstNameCompare === 0) {
            return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
          }
          return firstNameCompare;
        }));
      }
    }
    
    loadPatients();
  }, [isTeammate]);

  useEffect(() => {

    if (!productTypes) return

    const dashboardItem = {
      title: 'Dashboard',
      onClick: () => navigate('/')
    }

    const giftItem = {
      title: <>Gift Instalab</>,
      onClick: () => navigate(`/flow/${FlowType.GIFT}`)
    }

    const logoutItem = {
      title: "Logout",
      onClick: onLogout,
    };

    const loginItem = {
      title: "Login",
      onClick: () => navigate("/login"),
    };

    const testItem = {
      title: "Popular Tests",
      dropdownItems: [
        {
          key: 'longevity',
          label: (
            <a onClick={() => navigate(`${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.path}`)}>
              <span style={{fontWeight:600}}>Most start with:</span> <br/>{getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}
            </a>
          ),
        },
        {
          type: 'divider',
        },

        {
          key: 'cac',
          label: (
            <a onClick={() => navigate(`/cac`)}>
              Coronary Calcium Scan
            </a>
          )
        },
        // {
        //   key: 'fertility',
        //   label: (
        //     <a onClick={() => navigate(`/fertility`)}>
        //       Fertility Hormone Test
        //     </a>
        //   )
        // },
        {
          key: 'fit',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.FIT_STOOL}`)}>
              FIT Stool Test
            </a>
          )
        },
        {
          key: 'galleri',
          label: (
            <a onClick={() => navigate(`/galleri-test`)}>
              Galleri Cancer Detection Test
            </a>
          ),
        },
        {
          key: 'sleep',
          label: (
            <a onClick={() => navigate(`/sleep-apnea-test`)}>
              Sleep Apnea Test
            </a>
          ),
        },
        // {
        //   key: 'testosterone',
        //   label: (
        //     <a onClick={() => navigate(`/testosterone`)}>
        //       Testosterone Panel
        //     </a>
        //   ),
        // },
        {
          type: 'divider',
        },
        {
          key: 'view-all',
          label: (
            <a onClick={() => navigate(`/catalog`)}>
              View All Tests
            </a>
          ),
        }

      ]
    }

  
    let patientLinks = !isPro ? [testItem, giftItem] : []

    if (!token) {
      setHeaderItems([...patientLinks, loginItem]);
    } else if (currentUser?.role === Role.PATIENT) {
      setHeaderItems([dashboardItem, ...patientLinks, logoutItem]);
    } else if (
      currentUser?.role === Role.ADMIN ||
      currentUser?.role === Role.PROVIDER ||
      currentUser?.role === Role.STAFF ||
      currentUser?.role === Role.PHLEBOTOMIST
    ) {
      setHeaderItems([logoutItem]);
    }
  }, [currentUser, isPro, token, productTypes]);

  const onLogout = () => {
    navigate("/logout");
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const becomeMember = () => {
    navigate(`/flow/${FlowType.MEMBER}`)
  }

  const signUp = () => {
    navigate(`/signup`)
  }

  const HeaderItem = ({ title, path, onClick, dropdownItems }) => {
    if (dropdownItems) {

      return (
        <div className="header-item">
          <Dropdown menu={{ items: dropdownItems }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {title}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      );
    }

    else 
      return (
        <div className="header-item">
          <a
            key={`header-${title}`}
            onClick={() => {
              if (path) {
                window.open(path, "_blank");
              } else {
                onClick();
              }
            }}
          >
            {title}
          </a>
        </div>
      );
  };

  const DesktopHeader = () => {
    return (
      <>
      <AnnouncementBanner />

      <Row className="desktop-header">
        <Col>
          <div 
            className="logo-container"
            onClick={() => navigate((isPro && !token) ? '/pro' : '/')}
          >
            <img
              src={logo}
              alt="logo"
              className="logo"
            /> 
            <Text className="logo-name">
              {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> :  'Instalab'}
              {/* {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> : MembershipHelper.isPremium(instalabMembership) ? <>Instalab <Tag color="success">Premium</Tag></>  : 'Instalab'} */}
            </Text>
          </div> 
        </Col>

        <Col flex="auto" style={{ display: 'flex', justifyContent: 'center' }}>
          {isTeammate && (
            <PatientSearch patients={patients} navigate={navigate} currentUser={currentUser} />
          )}
        </Col>

        <Col>
          <div className="header-item-list">
            {headerItems.map(({ title, path, onClick, dropdownItems }, index) => (
              <HeaderItem
                key={`header-item-${index}`}
                index={index}
                title={title}
                path={path}
                onClick={onClick}
                dropdownItems={dropdownItems}
              />
            ))}
            {(token && !instalabMembership && currentUser?.role === Role.PATIENT) && <Button type="default" onClick={becomeMember} style={{fontWeight:600}}>Become a Member</Button>}
            {!token && 
              <><Button type="default" onClick={signUp} style={{fontWeight:600}}>Sign Up</Button></>
            }
          </div>
          
        </Col>
      </Row>
      </>
    );
  };

  return (hasMobile || width) && <>
    {(!hasMobile || width > Breakpoint.LG) ? <DesktopHeader /> : <MobileHeaderLayout isPro={isPro}  />}
  </>
};
