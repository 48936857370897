import React, { useState } from 'react'
import { Modal, Form, message } from 'antd'
import './transferModal.scss'
import { ContinueButton } from '../continueButton/continueButton.component'
import { Selector } from 'antd-mobile'
import { addTransfer } from '../../services/transfer.service'
import ProductTypeCode from "../../enums/productTypeCode.enum"

const { Item } = Form

export const TransferModal = ({ userId, open, setOpen, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState()

  const productTypeCodes = [
    ProductTypeCode.CONSULT_LONGEVITY,
    ProductTypeCode.WATCHPAT_ONE,
    ProductTypeCode.OMEGA_3_ADDON,
    ProductTypeCode.OMEGACHECK,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.MOBILE_BLOOD_DRAW,
    ProductTypeCode.PRENUVO,
    ProductTypeCode.FERTILITY,
    ProductTypeCode.TESTOSTERONE_PANEL_KIT,
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.CUSTOM_PANEL
  ]

  const onCancel = () => {
    setOpen(false)
    form.resetFields()
  }

  const onSave = async () => {
    setIsSubmitting(true)
    try {
      const { plan, productTypeCode } = form.getFieldsValue()
      if (!plan?.length && !productTypeCode?.length) {
        message.error('Select a product')
        setIsSubmitting(false)
        return
      }
      const transfer = await addTransfer({
        user: userId,
        // plan: plan ? plan[0] : null,
        productTypeCode: productTypeCode ? productTypeCode[0] : null
      })
      onSuccess(transfer)
      message.info('Transferred credit')
      onCancel()
    } catch (err) {
      message.error('Failed to transfer credit')
    }
    setIsSubmitting(false)
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="transfer-modal"
      title={'Gift Credit'}
      footer={[
        <ContinueButton
          onClick={onSave}
          text='Send Credit'
          isSubmitting={isSubmitting}
        />
      ]}
      width={500}
    >
      <Form
        form={form}
        onFinish={onSave}
        className="intake-form"
      >

        <Item name='productTypeCode'>
          <Selector
            multiple={false}
            options={productTypeCodes.map(productTypeCode => {
              return {
                label: productTypeCode,
                value: productTypeCode
              }
            })}
          />
        </Item>
      </Form>
    </Modal>
  )
}